// Chakra Icons
// Chakra Imports
import {
  Box,
  Button,
  Flex,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
// Assets
import avatar1 from 'assets/img/avatars/avatar1.png';
import avatar2 from 'assets/img/avatars/avatar2.png';
import avatar3 from 'assets/img/avatars/avatar3.png';
import IconAdd from 'assets/svg/ico-add.svg';
// Custom Icons
import { ArgonLogoDark, ArgonLogoLight, ChakraLogoDark, ChakraLogoLight, ProfileIcon, SettingsIcon } from 'components/Icons/Icons';
// Custom Components
import { ItemContent } from 'components/Menu/ItemContent';
import { SearchBar } from 'components/Navbars/SearchBar/SearchBar';
import { SidebarResponsive } from 'components/Sidebar/Sidebar';
import { useUserState } from 'context/UserContext';
import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import routes from 'routes.js';
import { useUserDispatch, signOut } from 'context/UserContext';
import { Roles, URL_LANDING_PAGE } from 'constants/common';
import logoImg from 'assets/img/logo.png';
import { useQueryMemberProfile } from 'services/user';
import { CookieStorage } from 'utils/cookie-storage';
import { formatCurrencyToVND } from 'utils/helpers';

export default function HeaderLinks(props) {
  const { variant, children, fixed, scrolled, secondary, onOpen, ...rest } = props;

  const { colorMode } = useColorMode();
  const { isAuthenticated, userInfo } = useUserState();
  const userDispatch = useUserDispatch();
  const history = useHistory();
  const { data } = useQueryMemberProfile({ enabled: CookieStorage.isAuthenticated() && !userInfo?.role });

  // Chakra Color Mode
  let navbarIcon = fixed && scrolled ? useColorModeValue('gray.700', 'gray.200') : useColorModeValue('#3A3A3A', 'gray.200');
  let menuBg = useColorModeValue('white', 'navy.800');
  const notificationColor = useColorModeValue('gray.700', 'white');
  // if (secondary) {
  //   navbarIcon = 'white';
  // }

  const handleToProfile = () => {
    history.push({
      pathname: '/admin/profile',
    });
  };
  return (
    <Flex pe={{ sm: '0px', md: '16px' }} w={{ sm: '100%', md: 'auto' }} alignItems="center" flexDirection="row">
      {/* <SearchBar me="18px" /> */}
      {isAuthenticated ? (
        <Flex flexDirection="row" alignItems={'center'} mr={3}>
          {
            (!userInfo?.role || userInfo?.role !== Roles.ADMIN) &&
            <Button p="0px" bg="transparent" _hover={{ bg: 'none', boxShadow: '1px 4px 13px 0px #1816690F' }} mr={8} onClick={() => history.push('/admin/deposit')}>
              <Flex
                align="center"
                w={{ sm: '100%' }}
                bg={'hsla(0,0%,100%,.3)'}
                borderRadius="15px"
                justifyContent="center"
                py="7px"
                px={3}
                boxShadow={'1px 4px 13px 0px #1816690F'}
                border={'1px solid #DDE2EE'}
                cursor="pointer"
              >
                <img src={IconAdd} width={'24px'} height="24px" alt="logo" />
                <Text fontSize="15px" color={'#1951EE'} fontWeight="bold" ms="6px">
                  Nạp tiền
                </Text>
              </Flex>
            </Button>
          }
          <Menu>
            <MenuButton display="flex" flexDirection="row" alignItems={'center'}>
              {userInfo?.role !== 'admin' && (
                <Text display={{ sm: 'none', md: 'inline-flex' }} color={'orange'} pr={6}>
                  {formatCurrencyToVND(data?.data?.balance || userInfo?.balance)}
                </Text>
              )}
              <ProfileIcon color={navbarIcon} w="22px" h="22px" me="0px" />
              <Text display={{ sm: 'none', md: 'inline-flex' }} color={navbarIcon}>
                {userInfo?.username}
              </Text>
            </MenuButton>
            <MenuList padding={'0'} bg={menuBg}>
              <Flex flexDirection="column">
                <MenuItem padding={'10px 14px'} mb="10px">
                  <ItemContent
                    info={userInfo?.username}
                    boldInfo="Username"
                    aSrc={avatar1}
                    // additionalComponent={
                    //   <div onClick={() => signOut(userDispatch, history)}>
                    //     <Text color={notificationColor}>Log out</Text>
                    //   </div>
                    // }
                  />
                </MenuItem>
                <MenuItem _hover={{ color: 'blue.600' }} color={'gray.500'} padding={'10px 14px'} onClick={() => handleToProfile()}>
                  <ProfileIcon w="22px" h="22px" me="0px" />
                  <Text ml={2} fontWeight="bold">
                    Profile
                  </Text>
                </MenuItem>
                <Button width={'fit-content'} margin={'18px'} padding={'2px 18px'} onClick={() => signOut(userDispatch, history)}>
                  <Text fontWeight="bold" color={notificationColor}>
                    Log out
                  </Text>
                </Button>
              </Flex>
            </MenuList>
          </Menu>
        </Flex>
      ) : (
        <NavLink to="/auth/sign-in">
          <Button
            ms="0px"
            px="0px"
            me={{ sm: '2px', md: '16px' }}
            color={navbarIcon}
            variant="no-effects"
            rightIcon={document.documentElement.dir ? '' : <ProfileIcon color={navbarIcon} w="22px" h="22px" me="0px" />}
            leftIcon={document.documentElement.dir ? <ProfileIcon color={navbarIcon} w="22px" h="22px" me="0px" /> : ''}
          >
            <Text display={{ sm: 'none', md: 'flex' }}>Sign In</Text>
          </Button>
        </NavLink>
      )}

      <SidebarResponsive
        hamburgerColor={'#3A3A3A'}
        logo={
          <Stack direction="row" spacing="12px" align="center" justify="center">
            {/* {colorMode === "dark" ? (
              <ArgonLogoLight w="74px" h="27px" />
            ) : (
              <ArgonLogoDark w="74px" h="27px" />
            )}
            <Box
              w="1px"
              h="20px"
              bg={colorMode === "dark" ? "white" : "gray.700"}
            />
            {colorMode === "dark" ? (
              <ChakraLogoLight w="82px" h="21px" />
            ) : (
              <ChakraLogoDark w="82px" h="21px" />
            )} */}
            {/* <img src={logo} /> */}
            <Link href={URL_LANDING_PAGE}>
              <img src={logoImg} width={'50px'} height="60px" alt="logo" />
            </Link>
          </Stack>
        }
        colorMode={colorMode}
        secondary={props.secondary}
        routes={routes}
        {...rest}
      />
      {/* <SettingsIcon
        cursor="pointer"
        ms={{ base: "16px", xl: "0px" }}
        me="16px"
        onClick={props.onOpen}
        color={navbarIcon}
        w="18px"
        h="18px"
      /> */}
      {/* <Menu>
        <MenuButton>
          <BellIcon color={navbarIcon} w="18px" h="18px" />
        </MenuButton>
        <MenuList p="16px 8px" bg={menuBg}>
          <Flex flexDirection="column">
            <MenuItem borderRadius="8px" mb="10px">
              <ItemContent
                time="13 minutes ago"
                info="from Alicia"
                boldInfo="New Message"
                aName="Alicia"
                aSrc={avatar1}
              />
            </MenuItem>
            <MenuItem borderRadius="8px" mb="10px">
              <ItemContent
                time="2 days ago"
                info="by Josh Henry"
                boldInfo="New Album"
                aName="Josh Henry"
                aSrc={avatar2}
              />
            </MenuItem>
            <MenuItem borderRadius="8px">
              <ItemContent
                time="3 days ago"
                info="Payment succesfully completed!"
                boldInfo=""
                aName="Kara"
                aSrc={avatar3}
              />
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu> */}
    </Flex>
  );
}
