// components/TelegramIcon.js
import React from 'react';
import { Box, IconButton, Text } from '@chakra-ui/react';
import { FaTelegramPlane } from 'react-icons/fa';

const TelegramIcon = () => {
  return (
    <Box
      position="fixed"
      bottom="20px"
      right="20px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      transition="transform 0.2s"
      _hover={{
        transform: 'translateY(-5px)',
      }}
    >
      <Text
        ml={2}
        fontSize="lg"
        color="blue.500"
        display={{ base: 'none', md: 'block' }}
      >
        Liên hệ
      </Text>
      <IconButton
        as="a"
        href="https://t.me/vnotp_admin"
        aria-label="Telegram"
        icon={<FaTelegramPlane size={30} />}
        //   position="fixed"
        //   bottom="20px"
        //   right="20px"
        width="50px" // Kích thước tùy chỉnh
        height="50px"
        colorScheme="blue"
        isRound
        target="_blank"
        rel="noopener noreferrer"
      />
    </Box>
  );
};

export default TelegramIcon;
