import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Flex,
    Stack,
    Text,
    useColorModeValue,
    useDisclosure,
  } from '@chakra-ui/react';
  import React, { useEffect, useState } from 'react';
  import { CookieStorage } from 'utils/cookie-storage';
  import { useHistory } from 'react-router-dom';
  import { useMemo } from 'react';
  import { ModalType } from 'constants/common';
  import SizeTable from './components/Table';
  import Pagination from 'components/Pagination/Pagination';
import { useDropIpMutation, useQueryGetListIp } from 'services/whitelistIp';
import FormIP from './components/FormIPs';
  function WhilteListIp() {
    const isLoggedIn = CookieStorage.isAuthenticated();
    const textColor = useColorModeValue('gray.700', 'white');
    const history = useHistory();
    const [sizeEditing, setSizeEditing] = useState(null);
    const [filter, setFilter] = useState({
      pageIndex: 0,
      pageSize: 20,
    });
  
    useEffect(() => {
      if (!isLoggedIn) {
        return history.push('/auth/sign-in');
      }
    }, [isLoggedIn, history]);
  
    const { isOpen: isCreateModalOpen, onOpen: onOpenCreateModal, onClose: onCloseCreateModal } = useDisclosure();
  
    const openModal = useMemo(
      () => ({
        [ModalType.Add]: onOpenCreateModal,
      }),
      [onOpenCreateModal]
    );
    const closeModal = useMemo(
      () => ({
        [ModalType.Add]: onCloseCreateModal,
      }),
      [onCloseCreateModal]
    );
  
    const { data: members, refetch } = useQueryGetListIp({ ...filter }, { enabled: isLoggedIn });
    const dropCollectionIps = useDropIpMutation()
  
    // const handleUpdateItem = (size, modalType) => {
    //   openModal?.[modalType]?.();
    //   setSizeEditing(size);
    // };
  
    const handleCloseModal = modalType => {
      closeModal?.[modalType]?.();
      setSizeEditing(null);
    };
  
    const handleDeleteSize = async () => {
      const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa danh sách địa chỉ này không?');
      if (!confirmDelete) {
        return;
      }
      dropCollectionIps.mutate(undefined,
        {
          onSuccess: () => {
            toast.showMessageSuccess('Xóa danh sách địa chỉ thành công');
            refetch?.();
          },
          onError: () => {
            toast.showMessageError('Xóa danh sách địa chỉ không thành công');
            refetch?.();
          },
        }
      );
    };
  
    // const handleSearch = () => {
    //   setFilter({
    //     ...filter,
    //     searchKeyword: searchTitle,
    //     companyId: companyFilter?.value,
    //   });
    // };
  
    // const handleReset = () => {
    //   setFilter({
    //     pageIndex: 0,
    //     pageSize: 100,
    //   });
    //   setCompanyFilter(null);
    //   setSearchTitle('');
    // };
    return (
      <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
        <Card p="16px" mb="24px" bg="#fff">
          <CardHeader p="12px 5px" mb="12px">
            <Flex justifyContent={'space-between'}>
              <Flex direction={'column'} w={'full'}>
                <Flex justifyContent={'space-between'} gap={'30px'}>
                  <Text fontSize="xl" color={textColor} fontWeight="bold">
                    Quản lý địa chỉ IP
                  </Text>
                  <Flex alignItems={"center"} gap={5}>
                    {/* <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} onClick={handleDeleteSize}>
                      Xóa danh sách địa chỉ
                    </Button> */}
                    <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} onClick={onOpenCreateModal}>
                      Tạo địa chỉ ip
                    </Button>
                  </Flex>
                </Flex>
                {/* <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'20px'}>
                  <Stack>
                    <Flex alignItems={'center'} gap={'20px'} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                      <FormControl minWidth={{ base: 'full', sm: '300px' }}>
                        <FormLabel>Tìm kiếm địa chỉ</FormLabel>
                        <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                      </FormControl>
                      <Button px={10} maxH="40px" alignSelf={'end'} onClick={handleReset}>
                        <Text fontSize="md" fontWeight="normal" cursor="pointer">
                          Cài lại
                        </Text>
                      </Button>
                      <Button variant="primary" px={10} maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                        <Text fontSize="md" fontWeight="normal" cursor="pointer">
                          Tìm kiếm
                        </Text>
                      </Button>
                    </Flex>
                  </Stack>
                </Flex> */}
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody overflowX="auto">
            <Stack overflow={'auto'}>
              <SizeTable categorysData={members?.data || []} refetch={refetch} />
            </Stack>
            <Flex justifyContent={'flex-end'}>
              <Pagination
                page={members?.pagination?.page}
                pageLength={members?.pagination?.pageSize}
                totalRecords={members?.pagination?.count}
                onPageChange={(page, pageLength) => {
                  setFilter({
                    ...filter,
                    pageSize: pageLength,
                    pageIndex: page - 1,
                  });
                }}
              />
            </Flex>
          </CardBody>
        </Card>
        {isCreateModalOpen && (
          <FormIP companyDetail={sizeEditing} isOpen={isCreateModalOpen} onClose={handleCloseModal} refetch={refetch} />
        )}
      </Flex>
    );
  }
  
  export default WhilteListIp;