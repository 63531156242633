import { useRef, useState } from 'react';
import { Button, Flex, FormControl, FormLabel, Input, Stack, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Pagination from 'components/Pagination/Pagination';
import ProductTable from './components/Table';
import { downloadFile } from 'utils/helpers';
import { toast } from 'components/Toast';
import { CountryCode, FileExcelValid, HistoriStatusOption, PhoneStatusOption } from 'constants/common';
import { Select } from 'chakra-react-select';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';
import { importPhone, useExportListPhoneMutation, useExportTemplatePhoneMutation, useQueryGetPhone } from 'services/phone';
import UpdatePhone from './components/UpdatePhone';

export default function Product() {
  const inputImportRef = useRef();
  const textColor = useColorModeValue('gray.700', 'white');
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 100,
  });
  const [searchTitle, setSearchTitle] = useState('');
  const [status, setStatus] = useState();
  const [countrie, setCountrie] = useState();
  const [phoneDetail, setPhoneDetail] = useState();
  const { userInfo } = useUserState();
  const { isOpen: isCreateModalOpen, onOpen: onOpenCreateModal, onClose: onCloseCreateModal } = useDisclosure();

  const exportTemplatePhoneMutation = useExportTemplatePhoneMutation(
    {},
    {
      responseType: 'arraybuffer',
    }
  );
  const exportTemplateListPhoneMutation = useExportListPhoneMutation(
    {
      ...filter,
      isExport: true,
      searchKeyword: searchTitle,
    },
    {
      responseType: 'arraybuffer',
    }
  );
  
  const { data: phones, refetch } = useQueryGetPhone(filter);

  const onDownloadTemplate = () => {
    exportTemplateListPhoneMutation.mutate(undefined, {
      onSuccess: response => {
        downloadFile(response, 'phone-template');
        toast.showMessageSuccess('Tải mẫu số điện thoại thành công');
      },
      onError: () => {
        toast.showMessageError('Tải mẫu số điện thoại thất bại');
      },
    });
  };

  const handleImportPhone = async e => {
    if (e?.target?.files?.[0]) {
      const productFile = e.target.files[0];
      const extensionFile = productFile?.name?.split('.')?.pop();

      if (FileExcelValid.includes(extensionFile)) {
        const formData = new FormData();

        formData.append('phoneFile', productFile);

        await importPhone(formData)
          .then(() => {
            toast.showMessageSuccess('Tải lên số điện thoại thành công');
            refetch();
          })
          .catch(() => {
            toast.showMessageError('Tải lên số điện thoại thất bại');
          });

        return;
      }

      setError('Chỉ hỗ trợ tải lại file định dạng .xlsx, xls');
    }
  };

  const onFilter = () => {
    setFilter({
      ...filter,
      searchKeyword: searchTitle,
      countryCode: countrie?.value,
      status: status?.value,
    });
  };

  const onReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 100,
    });
    setSearchTitle('');
    setStatus(null);
    setCountrie(null);
  };

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
        <Card p="16px" mb="24px" bg="#fff">
          <CardHeader p="12px 5px" mb="12px">
            <Flex justifyContent={'space-between'}>
              <Flex direction={'column'} width={'100%'}>
                {/* <Flex direction="column" gap={'30px'}>
                  <Text fontSize="xl" color={textColor} fontWeight="bold">
                    Quản lý số điện thoại
                  </Text>
                </Flex> */}
                <Flex gap={2} alignItems={'center'} justifyContent={'flex-end'} alignSelf={'start'} width={'100%'}>
                  <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'EXPORT_PRODUCT'}>
                    <Button fontSize="md" fontWeight="bold" variant="primary" isLoading={false} onClick={onDownloadTemplate}>
                      Export
                    </Button>
                    {/* <Flex alignItems="center">
                      <input type="file" hidden ref={inputImportRef} onChange={handleImportPhone} />
                      <Button
                        fontSize="md"
                        fontWeight="bold"
                        variant="primary"
                        onClick={() => {
                          inputImportRef?.current?.click();
                        }}
                      >
                        Import SĐT
                      </Button>
                    </Flex> */}
                  </AuthorizationComponentWrapper>
                  <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'UPDATE_PRODUCT'}>
                    <Button
                      variant="primary"
                      onClick={() => {
                        onOpenCreateModal();
                      }}
                    >
                      <Text fontSize="md" fontWeight="bold" cursor="pointer">
                        Thêm
                      </Text>
                    </Button>
                  </AuthorizationComponentWrapper>
                </Flex>
                <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} my={'20px'}>
                  <Stack>
                    <Flex gap={'20px'} flexDirection={'column'} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                      <Flex alignItems={'center'} justifyContent={'start'} gap={5} flexWrap="wrap">
                        <FormControl w="200px">
                          <FormLabel>Tìm kiếm</FormLabel>
                          <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                        </FormControl>
                        <FormControl w="200px">
                          <FormLabel>Quốc gia</FormLabel>
                          <Select
                            placeholder="Chọn quốc gia"
                            isClearable
                            value={countrie}
                            menuShouldBlockScroll
                            onChange={e => {
                              setCountrie(e);
                            }}
                            options={CountryCode}
                          ></Select>
                        </FormControl>
                        <FormControl  w="200px">
                          <FormLabel h={'21px'}>Trạng thái</FormLabel>
                          <Select
                            placeholder="Trạng thái"
                            isClearable
                            menuShouldBlockScroll
                            value={status}
                            onChange={e => {
                              setStatus(e);
                            }}
                            options={PhoneStatusOption}
                          ></Select>
                        </FormControl>
                        <Button maxH="40px" alignSelf={'end'} onClick={onReset}>
                          <Text fontSize="md" fontWeight="bold" cursor="pointer">
                            Cài lại
                          </Text>
                        </Button>
                        <Button variant="primary" maxH="40px" px={5} alignSelf={'end'} onClick={onFilter}>
                          <Text fontSize="md" fontWeight="bold" cursor="pointer">
                            Tìm kiếm
                          </Text>
                        </Button>
                      </Flex>
                    </Flex>
                  </Stack>
                </Flex>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody overflowX="auto">
            <Stack overflow={'auto'}>
              <ProductTable
                onOpen={onOpenCreateModal}
                setPhoneDetail={setPhoneDetail}
                productsData={phones?.data || []}
                refetch={refetch}
              />
            </Stack>
            <Flex justifyContent={'flex-end'}>
              {!isEmpty(phones?.data) && (
                <Pagination
                  page={phones?.pagination?.page}
                  pageLength={phones?.pagination?.pageSize}
                  totalRecords={phones?.pagination?.count}
                  onPageChange={(page, pageLength) => {
                    setFilter({
                      ...filter,
                      pageSize: pageLength,
                      pageIndex: page - 1,
                    });
                  }}
                />
              )}
            </Flex>
          </CardBody>
        </Card>
      </Flex>
      {isCreateModalOpen && (
        <UpdatePhone
          setPhoneDetail={setPhoneDetail}
          phoneDetail={phoneDetail}
          isOpen={isCreateModalOpen}
          onOpen={onOpenCreateModal}
          onClose={onCloseCreateModal}
          refetch={refetch}
        />
      )}
    </>
  );
}
