import { Box, Flex, IconButton, Image, Switch, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import { FORMAT_DATE, ModalType, Roles, ROOT_API } from 'constants/common';
import { toast } from 'components/Toast';
import { useSetBestCategoryMutation } from 'services/category';
import { formatCurrencyToVND, formatDate } from 'utils/helpers';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';
import { useDeleteServiceMutation, useSetBestServiceMutation, useSetPublishMutation } from 'services/service';
import { MdOutlineSettings } from 'react-icons/md';

const SizeTable = ({ categoryData, handleUpdateCategory, refetch }) => {
  const history = useHistory();
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const setBestServiceMutation = useSetBestServiceMutation();
  const deleteServiceMutation = useDeleteServiceMutation();
  const setPublishMutation = useSetPublishMutation();

  const handleRowClick = (ticket, type) => {
    handleUpdateCategory(ticket, type);
  };
  const { userInfo } = useUserState();

  const handleDeleteSize = async category => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa dịch vụ này không?');
    if (!confirmDelete) {
      return;
    }
    deleteServiceMutation.mutate(
      { id: category?._id },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Xóa dịch vụ thành công');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Xóa dịch vụ không thành công');
          refetch?.();
        },
      }
    );
  };

  const handleSetBestService = async service => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn đổi trạng thái dịch vụ tốt nhất này không?');
    if (!confirmDelete) {
      return;
    }
    setBestServiceMutation.mutate(
      { id: service?._id },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Đổi trạng thái dịch vụ tốt nhất thành công');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Đổi trạng thái dịch vụ tốt nhất không thành công');
          refetch?.();
        },
      }
    );
  };

  const onActionProduct = (productId, title, actionApi) => {
    actionApi.mutate(
      { id: productId },
      {
        onSuccess: () => {
          toast.showMessageSuccess(`${title} thành công`);
          refetch?.();
        },
        onError: () => {
          toast.showMessageError(`${title} thất bại`);
          refetch?.();
        },
      }
    );
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('thumbnails', {
        header: 'Ảnh',
        cell: info =>
          info.getValue() ? (
            <Image src={ROOT_API + '/' + info.getValue()} w="50px" h="50px" objectFit="contain" />
          ) : (
            <Image src="gibbresh.png" fallbackSrc="https://placehold.co/50?text=image&font=roboto" />
          ),
      }),
      columnHelper.accessor('title', {
        header: 'Tên',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('price', {
        header: 'Xu',
        cell: info =>formatCurrencyToVND(info.getValue()),
      }),
      // columnHelper.accessor('isPublished', {
      //   header: 'Trạng thái xuất bản',
      //   cell: info => (
      //     <Switch
      //       size="md"
      //       isChecked={info.getValue()}
      //       onChange={e => onActionProduct(info?.row?.original?._id, 'Thay đổi trạng thái xuất bản dịch vuụ', setPublishMutation)}
      //     />
      //   ),
      // }),
      // columnHelper.accessor('description', {
      //   header: 'Mô tả',
      //   cell: info => info.getValue(),
      // }),
      // columnHelper.accessor('createdAt', {
      //   header: 'Ngày tạo',
      //   cell: info => <Text whiteSpace={'nowrap'}>{formatDate(info.row.original.createdAt, FORMAT_DATE)}</Text>,
      // }),
      // columnHelper.accessor('action', {
      //   header: 'Thao tác',
      //   cell: info => (
      //     <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'UPDATE_PRODUCT_CATEGORY'}>
      //       <Flex alignItems="center" gap={1}>
      //         <IconButton
      //           bg="transparent"
      //           onClick={() => {
      //             handleSetBestService(info?.row?.original);
      //           }}
      //         >
      //           <MdOutlineSettings size={16} />
      //         </IconButton>
      //         <IconButton
      //           bg="transparent"
      //           onClick={() => {
      //             handleRowClick(info?.row?.original, ModalType.Add);
      //           }}
      //         >
      //           <EditIcon cursor="pointer" boxSize={4} />
      //         </IconButton>
      //         {/* <IconButton
      //         bg="transparent"
      //         onClick={() => {
      //           history.push(`/admin/category/${info?.row?.original?._id}/size`);
      //           }}
      //           >
      //         <TbTextResize size={16} />
      //         </IconButton>*/}
      //         <IconButton
      //           bg="transparent"
      //           onClick={() => {
      //             handleDeleteSize(info?.row?.original);
      //           }}
      //         >
      //           <DeleteIcon color="red.400" boxSize={4} />
      //         </IconButton>
      //       </Flex>
      //     </AuthorizationComponentWrapper>
      //   ),
      // }),
    ],
    [categoryData]
  );

  const table = useReactTable({
    data: categoryData || [],
    columns,
    state: {
      sorting,
    },
    initialState: {
      columnVisibility: {
        'action': userInfo?.role === Roles.ADMIN ? true : false
      }
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px" isTruncated>
                {header.isPlaceholder ? null : (
                  <Box cursor={header.column.getCanSort() ? 'pointer' : 'default'} onClick={header.column.getToggleSortingHandler()}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={4}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default SizeTable;
