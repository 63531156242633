import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import InputController from 'components/Form/InputController';
import { ModalType } from 'constants/common';
import { toast } from 'components/Toast';
import { CodeServiceFormValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCodeServiceMutation, useCodeServiceUpdateMutation } from 'services/service';

const CreateCategoryModal = ({ isOpen, categorysDetail, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const createCodeServiceMutation = useCodeServiceMutation();
  const updateCodeServiceMutation = useCodeServiceUpdateMutation();
  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(CodeServiceFormValidate),
    defaultValues: {
      name: '',
    },
  });

  useEffect(() => {
    if (categorysDetail) {
      reset({
        name: categorysDetail?.name,
        id: categorysDetail?._id,
      });
    }
  }, [categorysDetail]);

  const handleSuccess = () => {
    toast.showMessageSuccess(`${categorysDetail ? 'Cập nhật' : 'Tạo'} mã dịch vụ thành công`);
    refetch?.();
    onClose(ModalType.Add);
  };

  const onSubmit = async values => {
    if (categorysDetail) {
      updateCodeServiceMutation.mutate(
        { ...values, id: categorysDetail?._id },
        {
          onSuccess: () => {
            handleSuccess();
          },
        }
      );
    } else {
      createCodeServiceMutation.mutate(
        { ...values },
        {
          onSuccess: () => handleSuccess(),
        }
      );
    }
  };
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Add);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'700px'}>
          <AlertDialogHeader textTransform="uppercase">{categorysDetail ? 'Cập nhật' : 'Tạo'} mã dịch vụ</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <InputController control={control} name="name" label="Mã" />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Hủy
            </Button>
            <Button
              fontSize="md"
              fontWeight="bold"
              variant="primary"
              ml={3}
              isLoading={createCodeServiceMutation.isPending || updateCodeServiceMutation.isPending}
              onClick={handleSubmit(onSubmit)}
            >
              {categorysDetail ? 'Cập nhật' : 'Tạo'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default CreateCategoryModal;
