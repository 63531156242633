// Chakra imports
import { WarningIcon } from '@chakra-ui/icons';
import { Box, Button, Center, Flex, Link, SimpleGrid, Tag, TagLabel, TagLeftIcon, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import IconBox from 'components/Icons/IconBox';
import React from 'react';
import { BiMoneyWithdraw } from 'react-icons/bi';
import { FaQuestion, FaSimCard } from 'react-icons/fa';
import { TbBuildingCommunity } from 'react-icons/tb';

const StatisticalData = props => {
  const { title, value, icon } = props;
  const textColor = useColorModeValue('gray.700', 'white');
  
  return (
    <Card minH="125px" bgColor="white" borderRadius="8px">
      <Flex direction="row" mt={'16px'} ml={'32px'}>
        <Center>
          {icon}
          <Text fontSize="5xl" color={textColor} fontWeight="900">
            {value}
          </Text>
        </Center>
      </Flex>
      <Text fontSize="xl" color={textColor} fontWeight="bold">
        {title}
      </Text>
    </Card>
  );
};

const PlatformSettings = ({ title, subTitle, userInfo }) => {
  // Chakra color mode
  const textColor = useColorModeValue('gray.700', 'white');
  const accTele = 'https://t.me/vnotp_admin';
  const accTeleCommunity = 'https://t.me/khosim_vnotp';
  return (
    <Card p="16px">
      <CardHeader
        borderBottom="2px solid #eff2f5"
        p="0 5px 30px"
        mb="12px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          {title}
        </Text>
        {subTitle}
      </CardHeader>
      <CardBody px="5px">
        <SimpleGrid columns={{ sm: 1, md: 3, xl: 3 }} spacing="24px" mb="20px" mt="20px">
          <StatisticalData
            title="Số dư"
            value={userInfo?.balance || 0}
            icon={
              <IconBox borderRadius="50%" as="box" h={'45px'} w={'45px'} mr={'16px'}>
                <BiMoneyWithdraw size={'45'} />
              </IconBox>
            }
          />
          <StatisticalData
            title="Tổng code đã thuê"
            value={0}
            icon={
              <IconBox borderRadius="50%" as="box" h={'45px'} w={'45px'} mr={'16px'}>
                <FaSimCard size={'45'} />
              </IconBox>
            }
          />
          <StatisticalData
            title="Lần cuối thuê"
            value={0}
            icon={
              <IconBox borderRadius="50%" as="box" h={'45px'} w={'45px'} mr={'16px'}>
                <FaQuestion size={'45'} />
              </IconBox>
            }
          />
        </SimpleGrid>
        <Flex gap={6} alignItems={'center'} px={2} pt={5}>
          <Text fontWeight={600}>Telegram</Text>
          <Link href={accTele} target='_blank'>
            <Button variant="primary" px={6}>
              <Text fontSize="md" fontWeight="bold" cursor="pointer">
                Kết nối Telegram
              </Text>
            </Button>
          </Link>
        </Flex>
        <Box mt={10} border={'1px dashed #ffc700'} color="#5e6278" bg="#fff8dd" borderRadius="15px" p="12px 10px" mb="24px">
          <Tag variant="subtle" colorScheme="yellow">
            <TagLeftIcon boxSize="12px" as={WarningIcon} />
            <TagLabel>Chú ý</TagLabel>
          </Tag>
          <Text mt={1}>
            Vui lòng{' '}
            <Link color="blue.500" href={accTele} target='_blank'>
              Kết nôi Telegram Account
            </Link> để tiếp tục sử dụng dịch vụ và được hỗ trợ các tính năng bảo mật
          </Text>
        </Box>
        <Box mt={10} border={'1px dashed #ffc700'} color="#5e6278" bg="#fff8dd" borderRadius="15px" p="12px 10px" mb="24px" display="flex" alignItems="center" gap={2}>
          <Tag variant="subtle" colorScheme="yellow">
            <TbBuildingCommunity boxSize="12px" as={WarningIcon} />
          </Tag>
          <Text>
            Vui lòng{' '}
            <Link color="blue.500" href={accTeleCommunity} target='_blank'>
              kết nôi Telegram Account cộng đồng
            </Link> để sử dụng dịch vụ và được hỗ trợ tốt nhất 
          </Text>
        </Box>
      </CardBody>
    </Card>
  );
};

export default PlatformSettings;
