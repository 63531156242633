import React, { useState, useEffect } from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import { useHistory, useParams } from 'react-router-dom';
import InputController from 'components/Form/InputController';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { MissionFormValidate, TicketsFormValidate } from 'utils/validation';
import NumericInputController from 'components/Form/NumericInputController';
import { toast } from 'components/Toast';
import { FileImageValid, GameTypesMissionOption, LevelMemberOption, MISSION_TYPE, MissionOption, ROOT_API } from 'constants/common';
import { uploadThumbnailMission, useCreateMission, useQueryGetMissionDetail, useUpdateMission } from 'services/mission';
import SelectController from 'components/Form/SelectController';
import FileSelectController from 'components/Form/FileSelectController';
import { uploadAttachmentTicket, useCreateTicketMutation } from 'services/Tickets';

const checkTypeAbsoluteLevel = value => {
  return [MISSION_TYPE.BET_IN_DAY, MISSION_TYPE.BET_IN_WEEK].some(item => item === value);
};

export default function TicketForm() {
  const history = useHistory();
  const [fileSelected, setFileSelected] = useState({
    thumbnail: null,
  });
  const [error, setError] = useState({
    thumbnail: null,
  });

  const createTicketMutation = useCreateTicketMutation();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = useForm({
    resolver: yupResolver(TicketsFormValidate),
  });

  const onUploadPhoto = async () => {
    const formData = new FormData();

    if (!!fileSelected?.thumbnail?.file) {
      formData.append('thumbnailFile', fileSelected?.thumbnail?.file);

      const response = await uploadAttachmentTicket(formData);

      return response?.data;
    }

    return null;
  };

  const onSubmit = async dataForm => {
    const photosPath = await onUploadPhoto();
    createTicketMutation.mutate({
        ...dataForm,
        attachment :photosPath
    }, {
      onSuccess: () => {
        toast.showMessageSuccess('Tạo phiếu thành công');
        history.push('/admin/tickets');
      },
      onError: () => {
        toast.showMessageError('Tạo phiếu thất bại');
      },
    });
  };
  const handleFileSelect = (fieldName, e) => {
    if (e?.target?.files?.[0]) {
      const fileSelected = e.target.files[0];
      const filePreview = URL.createObjectURL(fileSelected);
      const extensionFile = fileSelected?.name?.split('.')?.pop()?.toLowerCase();

      if ([...FileImageValid].includes(extensionFile)) {
        setFileSelected(prev => ({
          ...prev,
          [fieldName]: { file: fileSelected, filePreview },
        }));

        return;
      }

      setFileSelected(prev => ({
        ...prev,
        [fieldName]: null,
      }));
      handleErrorFile(fieldName, 'File chỉ hỗ trợ định dạng .png, .jpeg, .jpg');
    }
  };

  const handleErrorFile = (fieldName, value) => {
    setError(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px" bg="white">
        <CardBody pb={4}>
          <form>
            <FileSelectController
              label="Hình ảnh"
              name="thumbnail"
              imgHeight="150px"
              imgWidth="150px"
              filePreview={fileSelected.thumbnail?.filePreview}
              error={error.thumbnail}
              handleFileSelect={handleFileSelect}
              handleErrorFile={handleErrorFile}
            />
            <InputController control={control} name="title" label="Tiêu đề" isRequired styleContainer={{ pt: '4' }} />
            <InputController control={control} name="content" label="Nội dung" isRequired styleContainer={{ pt: '4' }} />
          </form>
          <Flex pt={6} alignItems="flex-end" justifyContent="flex-end">
            <Button
              onClick={() => {
                history.goBack();
              }}
            >
              Hủy
            </Button>
            <Button
              isDisabled={!fileSelected?.thumbnail?.file && !isDirty}
              colorScheme="blue"
              ml={3}
              isLoading={createTicketMutation.isPending}
              onClick={handleSubmit(onSubmit)}
            >
              Tạo
            </Button>
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
}