import { Button, Flex, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import InputController from 'components/Form/InputController';
import { toast } from 'components/Toast';
import { SITE_URL } from 'constants/common';
import { StorageKeys } from 'constants/storage-keys';
import { useUserDispatch } from 'context/UserContext';
import omit from 'lodash/omit';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useUpdateProfileMemberMutation } from 'services/user';
import { CookieStorage } from 'utils/cookie-storage';
import { UpdateMemberProfileFormValidate } from 'utils/validation';

const UpdateInformationMember = ({ userInfo, setIsTabUpdateInfor, refetch }) => {
  // Chakra color mode
  const textColor = useColorModeValue('gray.700', 'white');
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(UpdateMemberProfileFormValidate),
  });
  // const userDispatch = useUserDispatch();
  const updateProfileMemberMutation = useUpdateProfileMemberMutation();

  const onSubmit = values => {
    updateProfileMemberMutation.mutate(
      { ...omit(values, 'username') },
      {
        onSuccess: response => {
          refetch();
          const { data } = response;
          // CookieStorage.setCookieData(StorageKeys.UserInfo, JSON.stringify(data));
          CookieStorage.setCookieData(StorageKeys.UserInfo, JSON.stringify(data), undefined, SITE_URL);
          // userDispatch({ type: 'LOGIN_SUCCESS', data: { data } });
          toast.showMessageSuccess('Cập nhật thông tin tài khoản thành công.');
          setIsTabUpdateInfor(false);
        },
      }
    );
  };

  useEffect(() => {
    reset({
      fullname: userInfo?.fullname,
      phone: userInfo?.phone,
    });
  }, [userInfo]);

  return (
    <Card p="16px">
      <CardHeader
        borderBottom="2px solid #eff2f5"
        p="10px 5px 30px"
        mb="12px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Thông tin tài khoản
        </Text>
      </CardHeader>
      <CardBody px="5px">
        <SimpleGrid columns={1} spacing="24px" mb="20px" mt="20px">
          <InputController
            disabled
            value={userInfo?.username}
            control={control}
            name="username"
            label="Tên đăng nhập"
            styleContainer={{ display: 'flex', alignItems: 'start', marginBottom: '15px' }}
            styleBoxInput={{ flex: 1 }}
            styleLabel={{ marginTop: '10px' }}
          />
          <InputController
            control={control}
            name="fullname"
            label="Họ và tên"
            styleContainer={{ display: 'flex', alignItems: 'start', marginBottom: '15px' }}
            styleBoxInput={{ flex: 1 }}
            styleLabel={{ marginTop: '10px' }}
          />
          <InputController
            control={control}
            type="number"
            name="phone"
            label="Số điện thoại"
            styleContainer={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}
            styleBoxInput={{ flex: 1 }}
          />
          <Flex justifyContent="end" gap={6}>
            <Button px={6} onClick={() => setIsTabUpdateInfor(false)}>
              <Text fontSize="md" fontWeight="bold" cursor="pointer">
                Hủy
              </Text>
            </Button>
            <Button variant="primary" px={6} onClick={handleSubmit(onSubmit)}>
              <Text fontSize="md" fontWeight="bold" cursor="pointer">
                Cập nhật thông tin
              </Text>
            </Button>
          </Flex>
        </SimpleGrid>
      </CardBody>
    </Card>
  );
};

export default UpdateInformationMember;
