import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getListTransaction = params => request.get('/api/v1/transactions-history-admin', { params });
export const getListMember = (params, headers) => request.get('/api/v1/member/members', { params, ...headers });
export const getListMemberHistories = params => request.get('/api/v1/member-histories/all', { params });
export const getListMemberHistoriesUpdateCoin = (params,headers) => request.get('/api/v1/histories-balance', { params,...headers});
export const getListMemberMissions = params => request.get('/api/v1/mission-member/member-histories', { params });
export const getListCollectCoin = params => request.get('/api/v1/mission-member/member-histories', { params });
export const adjustCoinMember = data => request.post('/api/v1/member/update-member-balance', data);

// Query
export const useQueryGetListTransaction = (params = {}, options = {}) =>
  useQuery({ queryKey: ['getListTransaction', params], queryFn: () => getListTransaction(params), ...options });
export const useQueryGetListMember = (params = {}, options = {}) =>
  useQuery({ queryKey: ['getListMember', params], queryFn: () => getListMember(params), ...options });
export const useQueryGetListMemberHistories = (params = {}, options = {}) =>
  useQuery({ queryKey: ['getListMemberHistories', params], queryFn: () => getListMemberHistories(params), ...options });
export const useQueryGetListMemberHistoriesUpdateCoin = (params = {}, options = {}) =>
  useQuery({ queryKey: ['getListMemberHistoriesCoin', params], queryFn: () => getListMemberHistoriesUpdateCoin(params), ...options });
export const useQueryGetListMemberMissions = (params = {}, options = {}) =>
  useQuery({ queryKey: ['getListMemberMissions', params], queryFn: () => getListMemberMissions(params), ...options });
export const useQueryGetListCollectCoin = (params = {}, options = {}) =>
  useQuery({ queryKey: ['getListCollectCoin', params], queryFn: () => getListCollectCoin(params), ...options });

// Mutation
export const useAdjustCoinMutation = () => useMutation({ mutationFn: adjustCoinMember });
export const useListMemberMutation = (params, headers) => useMutation({ mutationFn: () => getListMember(params, headers) });
export const useListHistoryMutation = (params, headers) => useMutation({ mutationFn: () => getListMemberHistoriesUpdateCoin(params, headers) });
