import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { AdjustCoinOption, GAME_TYPE_MISSION, GameTypesMissionOption, LevelMemberOptionFilter, ModalType } from 'constants/common';
// import CreateCategoryModal from './components/CreateCategoryModal';
import Pagination from 'components/Pagination/Pagination';
import { useListHistoryMutation, useQueryGetListMember, useQueryGetListMemberHistories, useQueryGetListMemberHistoriesUpdateCoin } from 'services/purchase-history';
import { Select } from 'chakra-react-select';
import HistoryTable from './HistoryTable';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import DatePicker from 'components/DatePicker/DatePicker';
import moment from 'moment';
import { toast } from 'components/Toast';
import { downloadFile } from 'utils/helpers';

function HistoriesUpdateCoin() {
  const isLoggedIn = CookieStorage.isAuthenticated();
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [sizeEditing, setSizeEditing] = useState(null);
  const [idMember, setIdMember] = useState();
  const [searchTitle, setSearchTitle] = useState('');
  const [type, setType] = useState();
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 100,
  });
  const [filterDate, setFilterDate] = useState({
    start: null,
    end: null,
  });
  const params = useParams();
  const { id } = params || {};

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/sign-in');
    }
  }, [isLoggedIn, history]);

  const { data: histories, refetch } = useQueryGetListMemberHistoriesUpdateCoin(
    { ...filter, searchKeyword: filter.searchKeyword, memberId: id },
    { enabled: isLoggedIn }
  );

  const exportTemplateHistoryMutation = useListHistoryMutation(
    {
      ...filter,
      isExport: true,
    },
    {
      responseType: 'arraybuffer',
    }
  );

  const handleUpdateItem = (size, modalType) => {
    openModal?.[modalType]?.();
    setSizeEditing(size);
  };

  const handleSearch = () => {
    setFilter({
      ...filter,
      ...(filterDate?.start && { start: moment(filterDate?.start).startOf('day').toISOString() }),
      ...(filterDate?.end && { end: moment(filterDate?.end).endOf('day').toISOString() }),
      searchKeyword: searchTitle,
      type: type?.value
    });
  };

  const handleReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 100,
    });
    setType(null);
    setSearchTitle('');
    setFilterDate({
      start: null,
      end: null,
    });
  };

  const onChangeDate = type => date => {
    setFilterDate(prev => ({
      ...prev,
      ...(type === 'start' && { end: null }),
      [type]: date,
    }));
  };

  const handleShowMemberDetail = (idMember, modalType) => {
    openModal?.[modalType]?.();
    setIdMember(idMember);
  };

  const onDownloadTemplate = () => {
    exportTemplateHistoryMutation.mutate(undefined, {
      onSuccess: response => {
        downloadFile(response, 'history-template');
        toast.showMessageSuccess('Tải mẫu lịch sử bù trừ xu thành công');
      },
      onError: (res) => {
        console.log(res);
        
        toast.showMessageError('Tải mẫu lịch sử bù trừ xu thất bại');
      },
    });
  };
  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'}>
              <Flex direction="column" gap={'10px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Lịch sử bù / trừ xu
                </Text>
                <Button fontSize="md" fontWeight="bold" variant='primary' isLoading={false} onClick={onDownloadTemplate}>
                  Xuất dữ liệu
                </Button>
                {/* <Text fontSize="xl" color={textColor} fontWeight="bold">
                  {histories?.member?.username
                    ? `HV: ${histories?.member?.username} - ${histories?.member?.brand} -  ${histories?.member?.coin} xu`
                    : ''}
                </Text> */}
              </Flex>
              <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'20px'}>
                <Stack>
                  <Flex alignItems={'center'} gap={'20px'} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                    <FormControl minWidth={{ base: 'full', sm: '300px' }}>
                      <FormLabel>Tìm kiếm Người Điều Chỉnh</FormLabel>
                      <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                    </FormControl>
                    <FormControl>
                      <FormLabel h={'21px'}>Loại Điều Chỉnh</FormLabel>
                      <Select
                        placeholder="Chọn loại"
                        isClearable
                        menuShouldBlockScroll
                        value={type}
                        onChange={e => {
                          setType(e);
                        }}
                        options={AdjustCoinOption}
                      ></Select>
                    </FormControl>
                    <FormControl>
                      <Flex alignItems={'center'} gap={4}>
                        <FormControl display="flex" flexDirection="column" maxW="300px">
                          <FormLabel marginRight={0} fontSize={14} isTruncated>
                            Ngày bắt đầu
                          </FormLabel>
                          <DatePicker
                            styleInput={{
                              minWidth: '300px',
                            }}
                            selectedDate={filterDate.start}
                            onChange={date => onChangeDate('start')(date)}
                          />
                        </FormControl>
                        <FormControl display="flex" flexDirection="column" maxW="300px">
                          <FormLabel marginRight={0} fontSize={14} isTruncated>
                            Ngày kết thúc
                          </FormLabel>
                          <DatePicker
                            styleInput={{
                              minWidth: '300px',
                            }}
                            selectedDate={filterDate.end}
                            minDate={filterDate.start}
                            // maxDate={new Date(formatDate(moment(filter.start).add(6, 'days')))}
                            onChange={date => onChangeDate('end')(date)}
                          />
                        </FormControl>
                      </Flex>
                    </FormControl>
                    <Button px={10} maxH="40px" alignSelf={'end'} onClick={handleReset}>
                      <Text fontSize="md" fontWeight="bold" cursor="pointer">
                        Cài lại
                      </Text>
                    </Button>
                    <Button variant="primary" px={10} maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                      <Text fontSize="md" fontWeight="bold" cursor="pointer">
                        Tìm kiếm
                      </Text>
                    </Button>
                  </Flex>
                </Stack>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'}>
            <HistoryTable
              tableData={histories?.data || []}
              handleUpdateCategory={handleUpdateItem}
              refetch={refetch}
              handleShowMemberDetail={handleShowMemberDetail}
            />
          </Stack>
          <Flex justifyContent={'flex-end'}>
            <Pagination
              page={histories?.pagination?.page}
              pageLength={histories?.pagination?.pageSize}
              totalRecords={histories?.pagination?.count}
              onPageChange={(page, pageLength) => {
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
              }}
            />
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default HistoriesUpdateCoin;
