import { Box, Table, Tbody, Td, Th, Thead, Tr, Flex, Menu, MenuButton, MenuList, MenuItem, Text } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { IconButton } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { ModalType, TYPE_MEMBER_HISTORY } from 'constants/common';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { FaHistory } from 'react-icons/fa';
import { ImCoinDollar } from 'react-icons/im';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';
import { formatCurrencyToVND, formatDateVN } from 'utils/helpers';

const SizeTable = ({ categoryData, handleShowMemberDetail }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const history = useHistory();
  const { userInfo } = useUserState();

  const handleViewClick = (idMember, ModalType) => {
    handleShowMemberDetail(idMember, ModalType);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('phoneService', {
        header: 'Dịch vụ thuê',
        cell: info => info.getValue()?.service?.title || info?.row?.original?.services?.join(','),
      }),
      columnHelper.accessor('phoneService', {
        header: 'Số thuê',
        cell: info => info.getValue() ? info.getValue()?.phoneNumber : info?.row?.original?.phones?.join(', '),
      }),
      columnHelper.accessor('type', {
        header: 'Loại',
        cell: info => info.getValue() === TYPE_MEMBER_HISTORY.BUY_PHONE ? "Thuê" : info.getValue() === TYPE_MEMBER_HISTORY.REFUND_BUY_PHONE_FAILED ? "Hoàn trả" : "--",
      }),
      columnHelper.accessor('preBalance', {
        header: 'Số xu hiện tại',
        cell: info =>formatCurrencyToVND(info.getValue()),
      }),
      columnHelper.accessor('currBalance', {
        header: 'Số xu trước đó',
        cell: info => formatCurrencyToVND(info.getValue()),
      }),
      columnHelper.accessor('amount', {
        header: 'Tổng',
        cell:info =>  `${info.row.original.type === TYPE_MEMBER_HISTORY.BUY_PHONE  ? '-' : info.row.original.type === TYPE_MEMBER_HISTORY.REFUND_BUY_PHONE_FAILED ? '+' : ''}${formatCurrencyToVND(info.getValue())}`,
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày tạo',
        cell: info => <Text whiteSpace={'nowrap'}>{formatDateVN(info.row.original.createdAt)}</Text>,
      }),
    ],
    [categoryData]
  );

  const table = useReactTable({
    data: categoryData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
    <Thead>
      {table.getHeaderGroups().map(headerGroup => (
        <Tr key={headerGroup.id}>
          {headerGroup.headers.map(header => (
            <Th key={header.id} w="120px" isTruncated>
              {header.isPlaceholder ? null : (
                <Box cursor={header.column.getCanSort() ? 'pointer' : 'default'} onClick={header.column.getToggleSortingHandler()}>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  {{
                    asc: ' 🔼',
                    desc: ' 🔽',
                  }[header.column.getIsSorted()] ?? null}
                </Box>
              )}
            </Th>
          ))}
        </Tr>
      ))}
    </Thead>
    <Tbody>
      {isEmpty(table.getRowModel().rows) ? (
        <Tr>
          <Td textAlign="center" colSpan={7}>
            Không có dữ liệu
          </Td>
        </Tr>
      ) : (
        table.getRowModel().rows.map(row => (
          <Tr key={row.id} isTruncated>
            {row.getVisibleCells().map(cell => (
              <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
            ))}
          </Tr>
        ))
      )}
    </Tbody>
  </Table>
  );
};

export default SizeTable;
