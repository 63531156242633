import React, { useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  FormControl,
  InputGroup,
  Flex,
  InputRightElement,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import InputController from 'components/Form/InputController';
import { ModalType } from 'constants/common';
import { toast } from 'components/Toast';
import { ChangePasswordFormValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useChangePasswordV2Mutation } from 'services/user';

const UpdatePassword = ({ isOpen, userDetail, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const updatePasswordMutation = useChangePasswordV2Mutation();
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(ChangePasswordFormValidate),
    defaultValues: {
      newPassword: '',
      password: '',
    },
  });
  const [show,setShow] = useState({
    password: ''
  })

  const handleSuccess = () => {
    toast.showMessageSuccess(`Cập nhật mật khẩu thành công`);
    refetch?.();
    onClose(ModalType.ResetPassword);
  };

  const onSubmit = async values => {
    updatePasswordMutation.mutate(
      { ...values },
      {
        onSuccess: () => handleSuccess(),
      }
    );
  };
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.ResetPassword);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'}>
          <AlertDialogHeader textTransform="uppercase">Cập nhật mật khẩu</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
              <Flex direction="column">
                <FormControl mb={3}>
                  <InputGroup size="md" position="relative">
                    <InputController
                      control={control}
                      hasIconPassword
                      type={show.password ? 'text' : 'password'}
                      name="password"
                      label="Mật khẩu cũ"
                      isRequired
                      styleContainer={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginBottom: '15px' }}
                      styleBoxInput={{ flex: 1, width: '100%' }}
                      styleLabel={{
                        fontWeight: 'bold',
                      }}
                    />
                    <InputRightElement width="4.5rem" position="absolute" top="28px" right="0px">
                      <Button
                        h="1.75rem"
                        size="sm"
                        onClick={() =>
                          setShow({
                            ...show,
                            password: !show.password,
                          })
                        }
                      >
                        {show.password ? 'Ẩn' : 'Hiện'}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <InputGroup size="md" position="relative"  mb={3}>
                    <InputController
                      styleLabel={{
                        fontWeight: 'bold',
                      }}
                      control={control}
                      hasIconPassword
                      type={show.password ? 'text' : 'password'}
                      name="newPassword"
                      label="Mật khẩu mới"
                      isRequired
                      styleContainer={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
                      styleBoxInput={{ flex: 1, width: '100%' }}
                    />
                    <InputRightElement width="4.5rem" position="absolute" top="28px" right="0px">
                      <Button
                        h="1.75rem"
                        size="sm"
                        onClick={() =>
                          setShow({
                            ...show,
                            password: !show.password,
                          })
                        }
                      >
                        {show.password ? 'Ẩn' : 'Hiện'}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <InputGroup size="md" position="relative">
                    <InputController
                      styleLabel={{
                        fontWeight: 'bold',
                      }}
                      control={control}
                      hasIconPassword
                      type={show.password ? 'text' : 'password'}
                      name="passwordConf"
                      label="Xác nhận mật khẩu mới"
                      isRequired
                      styleContainer={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginBottom: '15px' }}
                      styleBoxInput={{ flex: 1, width: '100%' }}
                    />
                    <InputRightElement width="4.5rem" position="absolute" top="28px" right="0px">
                      <Button
                        h="1.75rem"
                        size="sm"
                        onClick={() =>
                          setShow({
                            ...show,
                            password: !show.password,
                          })
                        }
                      >
                        {show.password ? 'Ẩn' : 'Hiện'}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
              </Flex>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.ResetPassword);
              }}
            >
              Hủy
            </Button>
            <Button
              colorScheme="blue"
              ml={3}
              isLoading={updatePasswordMutation.isPending}
              onClick={handleSubmit(onSubmit)}
            >
              Cập nhật
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default UpdatePassword;
