import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getPhone = (params,headers) => request.get('/api/v1/phone', { params,...headers });
export const getServicePhone = (params) => request.get('/api/v1/services-by-phone', { params });
export const exportTemplatePhone = (params, headers) => request.get('/api/v1/phone/export-template', { params, ...headers });
export const createProduct = data => request.post('/api/v1/phone', data);
export const updateProduct = data => request.post('/api/v1/phone/update', data);
export const changeStatus = data => request.post('/api/v1/phone/update-status', data);
export const deletePhone = data => request.post('/api/v1/phone/delete', data);
export const deletePhones = data => request.post('/api/v1/phone/delete-phones', data);
export const uploadPhotoProduct = (data, config) =>
  request.post('/api/v1/products/upload-thumbnail', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...config,
  });
export const getProductDetail = id => request.get(`/api/v1/products/${id}`);
export const changeStatusProduct = data => request.post('/api/v1/products/change-status', data);
export const setNewProduct = data => request.post('/api/v1/products/set-is-new', data);
export const setHotProduct = data => request.post('/api/v1/products/set-is-best', data);
export const changeStatusSoldout = data => request.post('/api/v1/products/change-sold-out', data);
export const importPhone = (data, config) =>
  request.post('/api/v1/phone/import', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...config,
  });
  
// Mutation
export const useDeletePhoneMutation = () => useMutation({ mutationFn: deletePhone });
export const useDeletePhonesMutation = () => useMutation({ mutationFn: deletePhones});
export const useCreateProductMutation = () => useMutation({ mutationFn: createProduct });
export const useUpdateProductMutation = () => useMutation({ mutationFn: updateProduct });
export const useChangeStatusMutation = () => useMutation({ mutationFn: changeStatus });
export const useChangeStatusProductMutation = () => useMutation({ mutationFn: changeStatusProduct });
export const useSetNewProductMutation = () => useMutation({ mutationFn: setNewProduct });
export const useSetHotProductMutation = () => useMutation({ mutationFn: setHotProduct });
export const useChangeStatusSoldoutMutation = () => useMutation({ mutationFn: changeStatusSoldout });
export const useExportTemplatePhoneMutation = (params, headers) => useMutation({ mutationFn: () => exportTemplatePhone(params, headers) });
export const useExportListPhoneMutation = (params, headers) => useMutation({ mutationFn: () => getPhone(params, headers) });


// Query
export const useQueryGetCatForProduct = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_CATE_FOR_PRODUCT', params], queryFn: () => getCatForProduct(params), ...options });
export const useQueryGetPhone = (params = {}, options = {}) =>
  useQuery({ queryKey: ['getPhone', params], queryFn: () => getPhone(params), ...options });
export const useQueryGetProductDetail = (id, options = {}) =>
  useQuery({ queryKey: ['GET_PRODUCT_DETAIL', id], queryFn: () => getProductDetail(id), ...options });
export const useQueryGetPhoneService = (params, options = {}) =>
  useQuery({ queryKey: ['getServicePhone', params], queryFn: () => getServicePhone(params), ...options });
