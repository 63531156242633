import React, { useEffect } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  FormControl,
  Flex,
  Text,
  Grid,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { BrandFilter, ModalType, PermissionOption } from 'constants/common';
import { toast } from 'components/Toast';
import { AssignPermissionFormValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAssignPermissionMutation } from 'services/user';
import CheckboxController from 'components/Form/CheckboxController';
import isEmpty from 'lodash/isEmpty';

const AssignPermission = ({ isOpen, userDetail, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const assignPermissionMutation = useAssignPermissionMutation();
  const {
    control,
    handleSubmit,
    setValue
  } = useForm({
    resolver: yupResolver(AssignPermissionFormValidate),
    defaultValues: {},
  });
  const handleSuccess = () => {
    toast.showMessageSuccess(`Cập nhật quyền thành công`);
    refetch?.();
    onClose(ModalType.EmailBackup);
  };

  const onSubmit = async values => {
    const payloadPermission = Object.keys(values).filter(key => !!values[key]);
    assignPermissionMutation.mutate({
      permissions: payloadPermission,
      userId: userDetail?._id,
    }, {
      onSuccess: () => handleSuccess(),
    });
  };

  useEffect(() => {
    if (!isEmpty(userDetail)) {
      userDetail?.permissions?.forEach(item => {
        setValue(item, true);
      });
    }
  }, [userDetail]);
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.EmailBackup);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'98%'}>
          <AlertDialogHeader textTransform="uppercase">Cấp quyền truy cập</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Flex direction="column">
              <FormControl mb={3}>
                <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                  {PermissionOption?.map((item, index) => (
                    <Flex justifyContent="start" alignItems={'start'} gap={2}>
                      <CheckboxController
                        label=""
                        name={item?.value}
                        control={control}
                        options={BrandFilter}
                        isRequired
                        styleContainer={{ pb: '4', width: 'fit-content' }}
                      />
                      <Text>{item?.label}</Text>
                    </Flex>
                  ))}
                </Grid>
              </FormControl>
            </Flex>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.EmailBackup);
              }}
            >
              Hủy
            </Button>
            <Button colorScheme="blue" ml={3} isLoading={assignPermissionMutation.isPending} onClick={handleSubmit(onSubmit)}>
              Cập nhật
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default AssignPermission;
