import { useRef, useState } from 'react';
import { Button, Flex, FormControl, FormLabel, Input, Stack, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Pagination from 'components/Pagination/Pagination';
import BankTable from './components/Table';
import { toast } from 'components/Toast';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';
import { useCreateBankFromVietQRMutation, useQueryGetBanks } from 'services/bank';

export default function Bank() {
  const textColor = useColorModeValue('gray.700', 'white');
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 100,
  });
  const [searchTitle, setSearchTitle] = useState('');
  const [phoneDetail, setPhoneDetail] = useState();
  const { userInfo } = useUserState();
  const { isOpen: isCreateModalOpen, onOpen: onOpenCreateModal, onClose: onCloseCreateModal } = useDisclosure();

  const { data: bankData, refetch } = useQueryGetBanks(filter);
  const createBankFromVietQRMutation = useCreateBankFromVietQRMutation();

  const onFilter = () => {
    setFilter({
      ...filter,
      searchKeyword: searchTitle,
    });
  };

  const onReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 100,
    });
    setSearchTitle('');
  };

  const onCreateBank = () => {
    createBankFromVietQRMutation.mutate(
      {},
      {
        onSuccess: () => {
          toast.showMessageSuccess('Tạo ngân hàng thành công');
          refetch();
        },
      }
    );
  };

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
        <Card p="16px" mb="24px" bg="#fff">
          <CardHeader p="12px 5px" mb="12px">
            <Flex justifyContent={'space-between'}>
              <Flex direction={'column'}>
                <Flex direction="column" gap={'30px'}>
                  <Text fontSize="xl" color={textColor} fontWeight="bold">
                    Quản lý ngân hàng
                  </Text>
                </Flex>
                <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} my={'20px'}>
                  <Stack>
                    <Flex gap={'20px'} flexDirection={'column'} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                      <Flex alignItems={'center'} justifyContent={'start'} gap={5} flexWrap="wrap">
                        <FormControl w="200px">
                          <FormLabel>Tìm kiếm</FormLabel>
                          <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                        </FormControl>
                        <Button maxH="40px" alignSelf={'end'} onClick={onReset}>
                          <Text fontSize="md" fontWeight="bold" cursor="pointer">
                            Cài lại
                          </Text>
                        </Button>
                        <Button variant="primary" maxH="40px" px={5} alignSelf={'end'} onClick={onFilter}>
                          <Text fontSize="md" fontWeight="bold" cursor="pointer">
                            Tìm kiếm
                          </Text>
                        </Button>
                      </Flex>
                    </Flex>
                  </Stack>
                </Flex>
              </Flex>
              <Flex gap={2} alignItems={'center'} alignSelf={'start'}>
                <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'UPDATE_PRODUCT'}>
                  <Button
                    variant="primary"
                    onClick={() => {
                      onCreateBank();
                    }}
                  >
                    <Text fontSize="md" fontWeight="bold" cursor="pointer">
                      Thêm
                    </Text>
                  </Button>
                </AuthorizationComponentWrapper>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody overflowX="auto">
            <Stack overflow={'auto'}>
              <BankTable productsData={bankData?.data || []} />
            </Stack>
            <Flex justifyContent={'flex-end'}>
              {!isEmpty(bankData?.data) && (
                <Pagination
                  page={bankData?.pagination?.page}
                  pageLength={bankData?.pagination?.pageSize}
                  totalRecords={bankData?.pagination?.count}
                  onPageChange={(page, pageLength) => {
                    setFilter({
                      ...filter,
                      pageSize: pageLength,
                      pageIndex: page - 1,
                    });
                  }}
                />
              )}
            </Flex>
          </CardBody>
        </Card>
      </Flex>
    </>
  );
}
