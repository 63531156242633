import { Box, Flex, IconButton, Image, Table, Tag, TagLabel, Tbody, Td, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import isEmpty from 'lodash/isEmpty';
import { formatCurrencyToVND, formatDate, formatDateVN } from 'utils/helpers';
import { toast } from 'components/Toast';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';
import { renderLabelColor } from 'utils/styles';
import { FORMAT_DATE, ModalType } from 'constants/common';

const DepositHistoryTable = ({ historyData, refetch, handleUpdateItem }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const { userInfo } = useUserState();

  const columns = useMemo(
    () =>
      [
        columnHelper.accessor('orderId', {
          header: 'ID',
          cell: info => info.renderValue(),
        }),
        userInfo?.role
          ? columnHelper.accessor('member', {
              header: 'Khách hàng',
              cell: info =>
                `${info.renderValue()?.fullname || ''} - ${info.renderValue()?.username || ''} - ${info.renderValue()?.phone || ''}`,
            })
          : null,
        columnHelper.accessor('amount', {
          header: 'Số xu',
          cell: info => <Box textTransform="capitalize">{formatCurrencyToVND(info.renderValue())}</Box>,
        }),
        columnHelper.accessor('type', {
          header: 'Nạp qua',
          cell: info => (info?.row?.original?.type === 'BANK_QR' ? 'Ngân hàng' : 'Momo'),
        }),
        // columnHelper.accessor('bank', {
        //   header: 'TK nhận ',
        //   cell: info => (
        //     <Box>
        //       <Box>{info.renderValue()?.bank}</Box>
        //       <Box>{info.renderValue()?.accountNo}</Box>
        //       <Box>{info.renderValue()?.accountName}</Box>
        //     </Box>
        //   ),
        // }),
        columnHelper.accessor('status', {
          header: 'Trạng thái',
          cell: info => (
            <Tag colorScheme={renderLabelColor(info.getValue())}>
              <TagLabel>{info.getValue()}</TagLabel>
            </Tag>
          ),
        }),
        columnHelper.accessor('createdAt', {
          header: 'Ngày tạo',
          cell: info => formatDateVN(info.getValue(), FORMAT_DATE),
        }),
        userInfo?.role
          ? columnHelper.accessor('action', {
              header: 'Thao tác',
              cell: info => (
                <Flex alignItems="center" gap={1}>
                  {/* <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'UPDATE_PRODUCT'}> */}
                  <IconButton
                    bg="transparent"
                    onClick={() => {
                      handleUpdateItem(info?.row?.original, ModalType.ChangeStatus);
                    }}
                  >
                    <EditIcon cursor="pointer" boxSize={4} />
                  </IconButton>
                  {/* <IconButton
                    bg="transparent"
                    onClick={() => {
                      handleDeleteSize(info?.row?.original);
                    }}
                  >
                    <DeleteIcon color="red.400" boxSize={4} />
                  </IconButton> */}
                  {/* </AuthorizationComponentWrapper> */}
                </Flex>
              ),
            })
          : null,
      ].filter(item => !!item),
    [historyData, userInfo]
  );

  const table = useReactTable({
    data: historyData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px" isTruncated>
                {header.isPlaceholder ? null : (
                  <Box
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                    isTruncated
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={10}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id} isTruncated>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default DepositHistoryTable;
