import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { Button, Flex, Input, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import Card from 'components/Card/Card';
import InputController from 'components/Form/InputController';
import { toast } from 'components/Toast';
import omit from 'lodash/omit';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useChangePasswordAdminMutation, useChangePasswordMemberMutation } from 'services/user';
import { ChangePasswordFormValidate } from 'utils/validation';
import { BO_URL, Roles } from 'constants/common';

const ProfileInformation = ({ title, userInfo, setIsTabUpdateInfor, setTabActive }) => {
  // Chakra color mode
  const textColor = useColorModeValue('gray.700', 'white');
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(ChangePasswordFormValidate),
  });
  const changePasswordMemberMutation = useChangePasswordMemberMutation();
  const changePasswordAdminMutation = useChangePasswordAdminMutation();

  const onSubmit = values => {
    ((BO_URL.includes('admin') || BO_URL === 'admin') ? changePasswordAdminMutation : changePasswordMemberMutation).mutate(
      { ...omit(values, 'username') },
      {
        onSuccess: response => {
          toast.showMessageSuccess('Thay đổi mật khẩu thành công');
        },
      }
    );
  };

  const onVerifyEmail = () => {};
  return (
    <Card p="16px" my={{ sm: '24px', xl: '0px' }}>
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          {title}
        </Text>
      </CardHeader>
      <CardBody px="5px">
        <SimpleGrid columns={1} spacing="24px" mb="20px" mt="20px">
          <Flex gap={10}>
            <Flex w="100%" alignItems={'center'}>
              <Text minW={'160px'}>Email</Text>
              <Input disabled value={userInfo?.email} name="username" label="Tên đăng nhập" />
            </Flex>
            <Button variant="primary" px={6} onClick={onVerifyEmail}>
              <Text fontSize="md" fontWeight="bold" cursor="pointer">
                Xác thực email
              </Text>
            </Button>
          </Flex>
          <InputController
            type="password"
            control={control}
            name="password"
            label="Mật khẩu hiện tại"
            styleContainer={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
            styleBoxInput={{ flex: 1 }}
          />
          <InputController
            type="password"
            control={control}
            name="newPassword"
            label="Mật khẩu mới"
            styleContainer={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
            styleBoxInput={{ flex: 1 }}
          />
          <InputController
            type="password"
            control={control}
            name="passwordConf"
            label="Nhập lại mật khẩu mới"
            styleContainer={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}
            styleBoxInput={{ flex: 1 }}
          />
          <Flex justifyContent="end" gap={6}>
            <Button px={6} onClick={() => {
              setTabActive(0)
              setIsTabUpdateInfor(false)
            }
            }>
              <Text fontSize="md" fontWeight="bold" cursor="pointer">
                Hủy
              </Text>
            </Button>
            <Button variant="primary" px={6} onClick={handleSubmit(onSubmit)}>
              <Text fontSize="md" fontWeight="bold" cursor="pointer">
                Lưu mật khẩu mới
              </Text>
            </Button>
          </Flex>
        </SimpleGrid>
      </CardBody>
    </Card>
  );
};

export default ProfileInformation;
