import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { ModalType, NetworkPhoneOption, NOTIFICATION } from 'constants/common';
import SizeTable from './components/Table';
import CreateCategoryModal from './components/CreateCategoryModal';
import Pagination from 'components/Pagination/Pagination';
import { toast } from 'components/Toast';
import { useCreateFastServiceMutation, useQueryGetListFastService, useQueryGetListService } from 'services/service';
import SelectController from 'components/Form/SelectController';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { RentSelectServiceFormValidate } from 'utils/validation';
import { mappingOptionSelect } from 'utils/mapping';
import { WarningIcon } from '@chakra-ui/icons';
import { socket } from 'config/socket';
import { useUserState } from 'context/UserContext';

function FastServiceOption() {
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [sizeEditing, setSizeEditing] = useState(null);
  const [searchTitle, setSearchTitle] = useState('');
  const [disable, setDisable] = useState(false);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 100,
  });
  const fastServiceCreate = useCreateFastServiceMutation();
  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(RentSelectServiceFormValidate),
    defaultValues: {
      network: undefined,
      serviceId: undefined,
    },
  });
  const { userInfo } = useUserState();

  useEffect(() => {
    if (!CookieStorage.isAuthenticated()) {
      return history.push('/auth/sign-in');
    }
  }, []);

  const { isOpen: isCreateModalOpen, onOpen: onOpenCreateModal, onClose: onCloseCreateModal } = useDisclosure();
  const { isOpen: isChangeStatusModalOpen, onOpen: onOpenChangeStatusModal, onClose: onCloseChangeStatusModal } = useDisclosure();
  const openModal = useMemo(
    () => ({
      [ModalType.Add]: onOpenCreateModal,
      [ModalType.ChangeStatus]: onOpenChangeStatusModal,
    }),
    [onOpenCreateModal, onOpenChangeStatusModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Add]: onCloseCreateModal,
      [ModalType.ChangeStatus]: onCloseChangeStatusModal,
    }),
    [onCloseCreateModal, onCloseChangeStatusModal]
  );

  const { data: services } = useQueryGetListService(
    { ...filter, searchKeyword: filter.searchTitle },
    { enabled: CookieStorage.isAuthenticated() }
  );

  const { data: fastServices, refetch } = useQueryGetListFastService(
    { ...filter, searchKeyword: filter.searchTitle },
    { enabled: CookieStorage.isAuthenticated() }
  );

  const handleUpdateItem = (size, modalType) => {
    openModal?.[modalType]?.();
    setSizeEditing(size);
  };

  const handelCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setSizeEditing(null);
  };

  const handleSearch = () => {
    setFilter({
      ...filter,
      searchTitle: searchTitle,
    });
  };

  const handleReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 100,
    });
    setSearchTitle('');
  };

  const onSubmit = values => {
    setDisable(true);
    fastServiceCreate.mutate(
      {
        network: values?.network?.value,
        serviceId: values?.serviceId?.value,
        countryCode: 'VN',
      },
      {
        onSuccess: response => {
          setDisable(false);
          refetch();
          toast.showMessageSuccess('Thuê số thành công');
        },
        onError: () => {
          setDisable(false);
        },
      }
    );
  };

  useEffect(() => {
    socket.on(`SEND_CODE_FOR_MEMBER_${userInfo?._id}`, res => {
      if (res) {
        refetch();
      }
    });
  }, []);
  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '50px' }}>
      <Card p="16px" mb="24px" bg="transparent">
        <Card mb={5} bg="transparent">
          <Text color="#f1416c" fontWeight={600}>
            <marquee>{NOTIFICATION.REFUND}</marquee>
          </Text>
          {/* <CardHeader p="12px 5px" mb="12px">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Thuê số tùy chọn
            </Text>
          </CardHeader> */}
          <CardBody
            px={{ base: '20px', md: '40px' }} // Padding responsive
            borderRadius="15px"
            bg="white"
            border="1px solid gray.200"
            boxShadow="inset 0 0 1px 1px hsl(0deg 0% 100% / 90%), 0 20px 27px 0 rgb(0 0 0 / 5%)"
          >
            <Flex
              gap={{ base: 4, md: 10 }} // Khoảng cách responsive
              alignItems={'end'}
              py={6}
              flexWrap={{ base: 'wrap', md: 'nowrap' }} // Hướng linh hoạt
            >
              <Flex direction="column" width={{ base: '100%', sm: '300px' }} mb={{ base: 4, md: 0 }}>
                <SelectController
                  label="Nhà mạng"
                  name="network"
                  placeholder="Chọn nhà mạng"
                  control={control}
                  options={NetworkPhoneOption}
                  isRequired
                  styleContainer={{ width: '100%' }} // Đặt chiều rộng 100% cho mobile
                  onChange={e => {
                    if (e.value === 'admin') {
                      setValue('brand', undefined);
                    }
                  }}
                />
              </Flex>
              <Flex direction="column" width={{ base: '100%', sm: '300px' }} mb={{ base: 4, md: 0 }}>
                <SelectController
                  label="Dịch vụ"
                  name="serviceId"
                  placeholder="Chọn chức vụ"
                  control={control}
                  options={mappingOptionSelect(services?.data, 'title', '_id')}
                  isRequired
                  styleContainer={{ width: '100%' }} // Đặt chiều rộng 100% cho mobile
                  onChange={e => {
                    if (e.value === 'admin') {
                      setValue('brand', undefined);
                    }
                  }}
                />
              </Flex>
              <Button
                isDisabled={disable}
                variant="primary"
                px={5}
                fontSize={{ base: 'sm', md: 'md' }} // Kích thước font responsive
                onClick={handleSubmit(onSubmit)}
                width={{ base: '100%', md: 'auto' }} // Đặt chiều rộng cho mobile
              >
                {'Thuê số ngay'}
              </Button>
            </Flex>
          </CardBody>
        </Card>
        <Box bg="white" className="shadow-bg" borderRadius="15px" p="24px">
          <Box border={'1px dashed #ffc700'} color="#5e6278" bg="#fff8dd" borderRadius="15px" p="12px 10px" mb="24px">
            <Tag variant="subtle" colorScheme="yellow">
              <TagLeftIcon boxSize="12px" as={WarningIcon} />
              <TagLabel>Chú ý</TagLabel>
            </Tag>
            <Text>
              Trường hợp cần thuê lại sim (chỉ lấy được trong thời gian ngắn 15-30p và sim khả dụng trên hệ thống), bấm vào nút gần số điện
              thoại đã thuê ở trang <br />
              Thuê số hoặc Lịch sử thuê số - Số sau khi mua mà không nhận được code sẽ tự động hoàn số dư sau khi hết
              <br />
              thời gian hết hạn( thường là 15 phút hoặc thấp hơn)
              <br />
              - Các dịch vụ như Gmail, Facebook, Zalo... khi đăng ký 2-3 acc sẽ bị các
              <br />
              trang đó check Ip và không gửi mã về, cần phải reset IP Internet lại (không phải IP máy tính)
              <br />
              - Shopee phải kiểm tra tài khoản
              <br />
              đã đăng ký chưa trước khi lấy code, hiện tại Shopee rất khó, cân nhắc khi sử dụng.
              <br />- Telegram cần kiểm tra tài khoản đăng ký trước khi lấy code.Cách kiểm tra : Vào Contact add contact Nếu Add contact
              không thành công là số đó chưa sử dụng.
            </Text>
          </Box>
          <CardHeader p="12px 5px" mb="12px">
            <Flex justifyContent={'space-between'}>
              <Flex direction={'column'}>
                <Flex direction="column" gap={'30px'}>
                  <Text fontSize="xl" color={textColor} fontWeight="bold">
                    Danh sách các số đang chờ tin nhắn
                  </Text>
                </Flex>
                <Flex
                  justifyContent={'space-between'}
                  alignItems={'end'}
                  gap={{ base: '10px', md: '20px' }} // Khoảng cách responsive
                  mt={'20px'}
                  flexWrap={{ base: 'wrap', md: 'nowrap' }} // Thay đổi hướng linh hoạt
                >
                  <Stack width={{ base: '100%', md: 'auto' }}>
                    {' '}
                    {/* Đặt chiều rộng cho mobile */}
                    <Flex
                      alignItems={'center'}
                      gap={{ base: '10px', md: '20px' }} // Khoảng cách responsive
                      flexWrap={{ base: 'wrap', md: 'nowrap' }} // Thay đổi hướng linh hoạt
                    >
                      <FormControl minWidth={{ base: 'full', sm: '200px' }}>
                        <FormLabel>Tìm kiếm</FormLabel>
                        <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                      </FormControl>
                      <Button maxH="40px" alignSelf={'end'} onClick={handleReset}>
                        <Text fontSize="md" fontWeight="bold" cursor="pointer">
                          Cài lại
                        </Text>
                      </Button>
                      <Button variant="primary" maxH="40px" px={5} alignSelf={'end'} onClick={handleSearch}>
                        <Text fontSize="md" fontWeight="bold" cursor="pointer">
                          Tìm kiếm
                        </Text>
                      </Button>
                    </Flex>
                  </Stack>
                </Flex>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody overflowX="auto">
            <Stack overflow={'auto'}>
              <SizeTable categoryData={fastServices?.data || []} handleUpdateCategory={handleUpdateItem} refetch={refetch} />
            </Stack>
            <Flex justifyContent={'flex-end'}>
              <Pagination
                page={fastServices?.pagination?.page}
                pageLength={fastServices?.pagination?.pageSize}
                totalRecords={fastServices?.pagination?.count}
                onPageChange={(page, pageLength) => {
                  setFilter({
                    ...filter,
                    pageSize: pageLength,
                    pageIndex: page - 1,
                  });
                }}
              />
            </Flex>
          </CardBody>
        </Box>
      </Card>
      {isCreateModalOpen && (
        <CreateCategoryModal categorysDetail={sizeEditing} isOpen={isCreateModalOpen} onClose={handelCloseModal} refetch={refetch} />
      )}
    </Flex>
  );
}

export default FastServiceOption;
