import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Text,
} from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import InputController from 'components/Form/InputController';
import { ModalType } from 'constants/common';
import { toast } from 'components/Toast';
import { ServiceFormValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCreateServiceMutation, useQueryGetCodeService, useUpdateServiceMutation, useUpdateThumbnailMutation } from 'services/service';
import { CookieStorage } from 'utils/cookie-storage';
import { mappingOptionSelect } from 'utils/mapping';
import SelectController from 'components/Form/SelectController';

const CreateCategoryModal = ({ isOpen, categorysDetail, onClose, refetch }) => {
  const params = useParams();
  const { id: categoryId } = params || {};
  const cancelRef = React.useRef();
  const createServiceMutation = useCreateServiceMutation();
  const updateServiceMutation = useUpdateServiceMutation();
  const updateThumnailMutation = useUpdateThumbnailMutation();
  const [file, setFile] = useState();
  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(ServiceFormValidate),
    defaultValues: {
      title: '',
      price: '',
      description: '',
      store: undefined
    },
  });
  const { data: codeServices } = useQueryGetCodeService(
    {},
    { enabled: CookieStorage.isAuthenticated() }
  );

  useEffect(() => {
    if (categorysDetail) {
      const codeServiceSelected = codeServices?.data?.find(item => item?._id === categorysDetail?.store?._id)
      reset({
        title: categorysDetail?.title,
        description: categorysDetail?.description,
        price: categorysDetail?.price,
        thumbnails: categorysDetail?.thumbnails,
        store: {
          label: codeServiceSelected?.name,
          value: codeServiceSelected?._id,
        },
        id: categorysDetail?._id,
      });
    }
    setFile(categorysDetail?.thumbnails);
  }, [categorysDetail]);

  const handleSuccess = () => {
    toast.showMessageSuccess(`${categorysDetail ? 'Cập nhật' : 'Tạo'} dịch vụ thành công`);
    refetch?.();
    onClose(ModalType.Add);
  };

  const handleError = error => {
    toast.showMessageError(
      error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || `${categorysDetail ? 'Cập nhật' : 'Tạo'} dịch vụ thành công`
    );
  };

  const onSubmit = async values => {
    if (categorysDetail) {
      if (categorysDetail.thumbnails === file) {
        updateServiceMutation.mutate(
          { ...values, id: categorysDetail?._id, store: values?.store?.label, price: Number(values?.price) },
          {
            onSuccess: () => {
              handleSuccess();
            },
            onError: error => handleError(error),
          }
        );
      } else {
        updateServiceMutation.mutate(
          { ...values, id: categorysDetail?._id, store: values?.store?.label, price: Number(values?.price) },
          {
            onSuccess: () => {
              handleSuccess();
            },
            onError: error => handleError(error),
          }
        );
      }
    } else {
      createServiceMutation.mutate(
        { ...values, store: values?.store?.label, price: Number(values?.price) },
        {
          onSuccess: () => handleSuccess(),
          onError: error => handleError(error),
        }
      );
    }
  };
  const handleFileChange = (e, field) => {
    if (e.target.files.length) {
      const formData = new FormData();
      formData.append('thumbnailFile', e.target.files[0]);
      updateThumnailMutation.mutate(formData, {
        onSuccess: res => {
          setFile(e.target.files[0].name);
          field.onChange(res?.data);
        },
      });
    }
  };
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Add);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'700px'}>
          <AlertDialogHeader textTransform="uppercase">{categorysDetail ? 'Cập nhật' : 'Tạo'} dịch vụ</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <InputController control={control} name="title" label="Tên" />
            <InputController
              control={control}
              name="price"
              label="Giá"
              type="number"
              styleContainer={{
                marginTop: '10px',
              }}
            />
            <InputController
              styleContainer={{
                marginTop: '10px',
              }}
              control={control}
              name="description"
              label="Mô tả"
              type="textarea"
            />
            <SelectController
              label="Store"
              name="store"
              placeholder="Chọn Store"
              control={control}
              options={mappingOptionSelect(codeServices?.data, 'name', '_id')}
              isRequired
              styleContainer={{ pt: '4' }}
            />
            <Controller
              control={control}
              name="thumbnails"
              render={({ field, fieldState: { error } }) => (
                <div className="z-0">
                  <div className="file-upload mt-2">
                    <div className="file-select font-montserrat rounded-lg">
                      <div className="file-select-button" id="fileName">
                        Chọn tệp
                      </div>
                      <div className="file-select-name" id="noFile">
                        {file ? file : `Không có tệp nào được chọn`}
                      </div>
                      <input type="file" name="chooseFile" id="chooseFile" onChange={e => handleFileChange(e, field)} />
                    </div>
                  </div>
                  {error && (
                    <Text color="#e53e3e" fontSize="13px" mt={2}>
                      {error.message}
                    </Text>
                  )}
                </div>
              )}
            />
            {file && <Text>File: {file}</Text>}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Hủy
            </Button>
            <Button
              fontSize="md"
              fontWeight="bold"
              variant="primary"
              ml={3}
              isLoading={createServiceMutation.isPending || updateServiceMutation.isPending}
              onClick={handleSubmit(onSubmit)}
            >
              {categorysDetail ? 'Cập nhật' : 'Tạo'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default CreateCategoryModal;
