import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Text,
} from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import InputController from 'components/Form/InputController';
import { CountryCode, ModalType, PhoneStatusOption } from 'constants/common';
import { toast } from 'components/Toast';
import { PhoneFormValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import SelectController from 'components/Form/SelectController';
import { useCreateProductMutation, useUpdateProductMutation } from 'services/phone';

const UpdatePhone = ({ isOpen, setPhoneDetail, phoneDetail, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const createServiceMutation = useCreateProductMutation();
  const updateServiceMutation = useUpdateProductMutation();
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(PhoneFormValidate),
    defaultValues: {
      phone: '',
      countryCode: undefined,
    },
  });

  useEffect(() => {
    if (phoneDetail) {
      reset({
        phone: phoneDetail?.phone,
        countryCode: CountryCode.find(country => country.value === phoneDetail?.countryCode),
        id: phoneDetail?._id,
      });
    }
  }, [phoneDetail]);

  const handleSuccess = () => {
    toast.showMessageSuccess(`${phoneDetail ? 'Cập nhật' : 'Tạo'} số điện thoại thành công`);
    refetch?.();
    onClose(ModalType.Add);
    setPhoneDetail(null);
  };
  const handleError = error => {
    toast.showMessageError(
      error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || `${phoneDetail ? 'Cập nhật' : 'Tạo'} số điện thoại thành công`
    );
    onClose(ModalType.Add);
    setPhoneDetail(null);
  };
  const onSubmit = async values => {
    if (phoneDetail) {
      updateServiceMutation.mutate(
        { phone: values?.phone, id: phoneDetail?._id },
        {
          onSuccess: () => {
            handleSuccess();
          },
          onError: error => handleError(error),
        }
      );
    } else {
      createServiceMutation.mutate(
        { ...values, countryCode: values?.countryCode?.value },
        {
          onSuccess: () => handleSuccess(),
          onError: error => handleError(error),
        }
      );
    }
  };
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Add);
          setPhoneDetail(null);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'700px'}>
          <AlertDialogHeader textTransform="uppercase">{phoneDetail ? 'Cập nhật' : 'Tạo'} số điện thoại</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <InputController control={control} name="phone" label="Số điện thoại" type="number" />
            <SelectController
              label="Quốc gia"
              name="countryCode"
              placeholder="Chọn quốc gia"
              control={control}
              options={CountryCode}
              isRequired
              styleContainer={{ py: '4' }}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Hủy
            </Button>
            <Button
              fontSize="md"
              fontWeight="bold"
              variant="primary"
              ml={3}
              isLoading={createServiceMutation.isPending || updateServiceMutation.isPending}
              onClick={handleSubmit(onSubmit)}
            >
              {phoneDetail ? 'Cập nhật' : 'Tạo'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default UpdatePhone;
