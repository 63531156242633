// Chakra imports
import { Avatar, Box, Button, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { Roles } from 'constants/common';
import React from 'react';
import { formatCurrencyToVND } from 'utils/helpers';

const Header = ({
  backgroundHeader,
  backgroundProfile,
  avatarImage,
  name,
  email,
  balance,
  phone,
  inviteCode,
  tabs,
  role,
  tabActive,
  setTabActive,
  tokenCode
}) => {
  // Chakra color mode
  const textColor = useColorModeValue('gray.700', 'white');
  const textColorRevert = useColorModeValue('white', '#1951EE');
  const borderProfileColor = useColorModeValue('white', 'rgba(255, 255, 255, 0.31)');
  const emailColor = useColorModeValue('#838383', 'gray.300');
  return (
    <Box
      mb={{ sm: '205px', md: '75px', xl: '70px' }}
      borderRadius="15px"
      px="0px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      align="center"
    >
      <Box
        bgImage={backgroundHeader}
        w="100%"
        h="300px"
        borderRadius="25px"
        bgPosition="50%"
        bgRepeat="no-repeat"
        position="relative"
        display="flex"
        justifyContent="center"
      >
        
        <Flex
          direction={{ sm: 'column', md: 'row' }}
          mx="1.5rem"
          maxH="380px"
          w={"100%"}
          justifyContent={{ sm: 'center', md: 'space-between' }}
          align="center"
          backdropFilter="saturate(200%) blur(50px)"
          position="absolute"
          boxShadow="0px 2px 5.5px rgba(0, 0, 0, 0.02)"
          border="2px solid"
          borderColor={borderProfileColor}
          bg={backgroundProfile}
          p="24px"
          borderRadius="20px"
          transform={{
            sm: 'translateY(30%)',
            md: 'translateY(80%)',
            lg: 'translateY(60%)',
          }}
        >
          <Flex
            align="center"
            mb={{ sm: '10px', md: '0px' }}
            direction={{ sm: 'column', md: 'row' }}
            w={{ sm: '100%' }}
            textAlign={{ sm: 'center', md: 'start' }}
          >
            <Avatar me={{ md: '22px' }} src={avatarImage} w="80px" h="80px" borderRadius="15px" />
            {role === Roles.ADMIN ? (
              <Flex direction="column" maxWidth="100%" my={{ sm: '14px' }}>
                <Text fontSize={{ sm: 'lg', lg: 'xl' }} color={textColor} fontWeight="bold" ms={{ sm: '8px', md: '0px' }}>
                  {name}
                </Text>
                <Text fontSize={{ sm: 'sm', md: 'md' }} color={emailColor} fontWeight="semibold">
                  Role: {role}
                </Text>
              </Flex>
            ) : (
              <Flex direction="column" maxWidth="100%" my={{ sm: '14px' }}>
                <Text fontSize={{ sm: 'lg', lg: 'xl' }} color={textColor} fontWeight="bold" ms={{ sm: '8px', md: '0px' }}>
                  {name}
                </Text>
                <Text fontSize={{ sm: 'sm', md: 'md' }} color={emailColor} fontWeight="medium">
                  Email: {email}
                </Text>
                <Text fontSize={{ sm: 'sm', md: 'md' }} color={emailColor} fontWeight="medium">
                  Số dư: {formatCurrencyToVND(balance)}
                </Text>
                <Text fontSize={{ sm: 'sm', md: 'md' }} color={emailColor} fontWeight="medium">
                  Số điện thoại: {phone}
                </Text>
                {/* <Text fontSize={{ sm: 'sm', md: 'md' }} color={emailColor} fontWeight="medium">
                 Token: {tokenCode}
                </Text> */}
                <Text fontSize={{ sm: 'sm', md: 'md' }} color={emailColor} fontWeight="medium">
                  Mã giới thiệu: {inviteCode}
                </Text>
              </Flex>
            )}
          </Flex>
          <Flex direction={{ sm: 'column', lg: 'row' }} w={{ sm: '100%', md: '50%', lg: 'auto' }} gap={4}>
            <Button rounded={16} p="0px" color={tabActive === 0 ? textColorRevert : textColor} _hover={{ bg: 'none', textColor: '#1951EE' }} bg={tabActive === 0 ? '#1951EE' : 'transparent'} border={`1px solid ${tabActive === 0 ? 'transparent' : '#DDE2EE'}`} onClick={() => setTabActive(0)}>
              <Flex
                align="center"
                w={{ sm: '100%', lg: '135px' }}
                justifyContent="center"
                py="10px"
                // boxShadow={tabActive === 0 && 'inset 0 0 1px 1px hsl(0deg 0% 100% / 90%), 0 20px 27px 0 rgb(0 0 0 / 5%)'}
                // border={tabActive === 0 && '1px solid gray.200'}
                cursor="pointer"
              >
                {tabs[0].icon}
                <Text fontSize="xs" fontWeight="bold" ms="6px">
                  {tabs[0].name}
                </Text>
              </Flex>
            </Button>
            <Button rounded={16} p="0px" color={tabActive === 1 ? textColorRevert : textColor} _hover={{ bg: 'none', textColor: '#1951EE' }} bg={tabActive === 1 ? '#1951EE' : 'transparent'} border={`1px solid ${tabActive === 1 ? 'transparent' : '#DDE2EE'}`} onClick={() => setTabActive(1)}>
              <Flex
                align="center"
                w={{ sm: '100%', lg: '135px' }}
                justifyContent="center"
                py="10px"
                // boxShadow={tabActive === 0 && 'inset 0 0 1px 1px hsl(0deg 0% 100% / 90%), 0 20px 27px 0 rgb(0 0 0 / 5%)'}
                // border={tabActive === 0 && '1px solid gray.200'}
                cursor="pointer"
              >
                {tabs[1].icon}
                <Text fontSize="xs" fontWeight="bold" ms="6px">
                  {tabs[1].name}
                </Text>
              </Flex>
            </Button>
            <Button rounded={16} p="0px" color={tabActive === 2 ? textColorRevert : textColor} _hover={{ bg: 'none', textColor: '#1951EE' }} bg={tabActive === 2 ? '#1951EE' : 'transparent'} border={`1px solid ${tabActive === 2 ? 'transparent' : '#DDE2EE'}`} onClick={() => setTabActive(2)}>
              <Flex
                align="center"
                w={{ sm: '100%', lg: '135px' }}
                justifyContent="center"
                py="10px"
                // boxShadow={tabActive === 0 && 'inset 0 0 1px 1px hsl(0deg 0% 100% / 90%), 0 20px 27px 0 rgb(0 0 0 / 5%)'}
                // border={tabActive === 0 && '1px solid gray.200'}
                cursor="pointer"
              >
                {tabs[2].icon}
                <Text fontSize="xs" fontWeight="bold" ms="6px">
                  {tabs[2].name}
                </Text>
              </Flex>
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default Header;
