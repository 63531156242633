import { Button, Card, CardBody, CardHeader, Flex, FormControl, FormLabel, Input, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import SizeTable from './components/Table';
import Pagination from 'components/Pagination/Pagination';
import { toast } from 'components/Toast';
import { useExportHistoryServicesMutation, useLisHistoryMutation, useQueryGetHistoryRentPhoneBO, useQueryGetListService } from 'services/service';
import { downloadFile } from 'utils/helpers';
import { socket } from 'config/socket';
import { HistoriStatusOption } from 'constants/common';
import { Select } from 'chakra-react-select';
import SelectController from 'components/Form/SelectController';
import { mappingOptionSelect } from 'utils/mapping';
import DatePicker from 'components/DatePicker/DatePicker';
import moment from 'moment';
function HistoryRentPhoneBo() {
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [sizeEditing, setSizeEditing] = useState(null);
  const [searchTitle, setSearchTitle] = useState('');
  const [service, setService] = useState(null);
  const [status, setStatus] = useState(null);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 100,
  });
  const [filterDate, setFilterDate] = useState({
    start: null,
    end: null,
  });
  const exportTemplateMutation = useExportHistoryServicesMutation(
    {},
    {
      responseType: 'arraybuffer',
    }
  );

  const { data: services } = useQueryGetListService({ ...filter }, { enabled: CookieStorage.isAuthenticated() });

  useEffect(() => {
    if (!CookieStorage.isAuthenticated()) {
      return history.push('/auth/sign-in');
    }
  }, []);

  const { data: histories, refetch } = useQueryGetHistoryRentPhoneBO(
    { ...filter, searchKeyword: filter.searchTitle },
    { enabled: CookieStorage.isAuthenticated() }
  );

  const exportTemplateHistoryMutation = useLisHistoryMutation(
    {
      ...filter,
      isExport: true,
      searchKeyword: searchTitle,
    },
    {
      responseType: 'arraybuffer',
    }
  );

  const handleUpdateItem = (size, modalType) => {
    openModal?.[modalType]?.();
    setSizeEditing(size);
  };

  const handelCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setSizeEditing(null);
  };

  const handleSearch = () => {
    setFilter({
      ...filter,
      ...(filterDate?.start && { start: moment(filterDate?.start).startOf('day').toISOString() }),
      ...(filterDate?.end && { end: moment(filterDate?.end).endOf('day').toISOString() }),
      searchTitle: searchTitle,
      status: status?.value,
      serviceId: service?.value,
    });
  };

  const handleReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 100,
    });
    setSearchTitle('');
    setStatus(null);
    setService(null);
    setFilterDate({
      start: null,
      end: null,
    });
  };

  const onChangeDate = type => date => {
    setFilterDate(prev => ({
      ...prev,
      ...(type === 'start' && { end: null }),
      [type]: date,
    }));
  };

  const onDownloadTemplate = () => {
    exportTemplateHistoryMutation.mutate(undefined, {
      onSuccess: response => {
        downloadFile(response, 'history-service-template');
        toast.showMessageSuccess('Tải dữ liệu lịch sử xuống thành công');
      },
      onError: () => {
        toast.showMessageError('Tải dữ liệu lịch sử xuống thất bại');
      },
    });
  };

  useEffect(() => {
    socket.on('SEND_CODE', res => {
      if (res) {
        refetch();
      }
    });
  }, []);
  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card px="16px" pb="16px" mb="24px" bg="#fff">
        <Card mb={5}>
          <CardHeader p="12px 5px" mb="12px" display={'flex'} justifyContent="space-between" alignItems="center">
            <Text fontSize="22px" color={textColor} fontWeight="bold">
              Lịch sử thuê số <br />
              <Text fontSize={12}>Danh sách {histories?.pagination?.pageSize} số gần nhất đã thuê</Text>
            </Text>
            {/* <Button onClick={() => history.push('/admin/fastrequestservices-option')} variant="primary">
              <Text fontSize="md" fontWeight="bold" cursor="pointer">
                Thuê số
              </Text>
            </Button> */}
            <Button fontSize="md" fontWeight="bold" variant="primary" isLoading={false} onClick={() => onDownloadTemplate()}>
              Export
            </Button>
          </CardHeader>
          <CardBody
            px="40px"
            borderRadius="15px"
            bg="white"
            border="1px solid gray.200"
            boxShadow="inset 0 0 1px 1px hsl(0deg 0% 100% / 90%), 0 20px 27px 0 rgb(0 0 0 / 5%)"
          >
            <Flex
              justifyContent="space-between"
              alignItems="center"
              gap="20px"
              mt="20px"
              flexDirection={{ base: 'column', md: 'row' }} // Stack on mobile
            >
              <Stack width="100%">
                <Flex gap="20px" alignItems="end" flexWrap={{ base: 'wrap', xl: 'nowrap' }}>
                  <FormControl width={{ base: 'full', md: '200px' }} mb={{ base: 2 }}>
                    <FormLabel>Tìm kiếm dịch vụ</FormLabel>
                    <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                  </FormControl>
                  <FormControl width={{ base: 'full', md: '200px' }} mb={{ base: 2 }}>
                    <FormLabel>Dịch vụ</FormLabel>
                    <Select
                      placeholder="Chọn dịch vụ"
                      isClearable
                      menuShouldBlockScroll
                      value={service}
                      options={mappingOptionSelect(services?.data, 'title', '_id')}
                      onChange={e => setService(e)}
                    />
                  </FormControl>
                  <FormControl width={{ base: 'full', md: '200px' }} mb={{ base: 2 }}>
                    <FormLabel>Trạng thái</FormLabel>
                    <Select
                      placeholder="Trạng thái"
                      isClearable
                      menuShouldBlockScroll
                      value={status}
                      onChange={e => setStatus(e)}
                      options={HistoriStatusOption}
                    />
                  </FormControl>
                  <Flex alignItems="center" gap={4}>
                    <FormControl display="flex" flexDirection="column" maxW="300px" mb={{ base: 2 }}>
                      <FormLabel fontSize={14}>Từ</FormLabel>
                      <DatePicker
                        styleInput={{ minWidth: '300px' }}
                        selectedDate={filterDate.start}
                        onChange={date => onChangeDate('start')(date)}
                      />
                    </FormControl>
                    <FormControl display="flex" flexDirection="column" maxW="300px" mb={{ base: 2 }}>
                      <FormLabel fontSize={14}>Đến</FormLabel>
                      <DatePicker
                        styleInput={{ minWidth: '300px' }}
                        selectedDate={filterDate.end}
                        minDate={filterDate.start}
                        onChange={date => onChangeDate('end')(date)}
                      />
                    </FormControl>
                  </Flex>
                  <Button maxH="40px" alignSelf="end" px={8} onClick={handleReset} mb={{ base: 2 }}>
                    <Text fontSize="md" fontWeight="bold">
                      Cài lại
                    </Text>
                  </Button>
                  <Button variant="primary" maxH="40px" px={10} alignSelf="end" onClick={handleSearch} mb={{ base: 2 }}>
                    <Text fontSize="md" fontWeight="bold">
                      Tìm kiếm
                    </Text>
                  </Button>
                </Flex>
              </Stack>
            </Flex>
          </CardBody>
        </Card>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'}>
            <SizeTable categoryData={histories?.data || []} handleUpdateCategory={handleUpdateItem} refetch={refetch} />
          </Stack>
          <Flex justifyContent={'flex-end'}>
            <Pagination
              page={histories?.pagination?.page}
              pageLength={histories?.pagination?.pageSize}
              totalRecords={histories?.pagination?.count}
              onPageChange={(page, pageLength) => {
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
              }}
            />
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default HistoryRentPhoneBo;
