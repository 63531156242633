import { Button, Center, Flex, Grid, Text, useColorModeValue } from '@chakra-ui/react';
import avatar4 from 'assets/img/BackgroundCard1.png';
import React, { useState } from 'react';
import { FaCube, FaPenFancy, FaRegCopy } from 'react-icons/fa';
import { IoDocumentsSharp } from 'react-icons/io5';
import Conversations from './Profile/Conversations';
import Header from './Profile/Header';
import PlatformSettings from './Profile/PlatformSettings';
import ProfileInformation from './Profile/ProfileInformation';
import UpdateInformationMember from './Profile/UpdateInformationMember';
import { useQueryMemberProfile } from 'services/user';
import { CookieStorage } from 'utils/cookie-storage';
import { useUserState } from 'context/UserContext';
import { toast } from 'components/Toast';
import { Roles } from 'constants/common';
function Profile() {
  const bgProfile = useColorModeValue(
    'hsla(0,0%,100%,.8)',
    'linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)'
  );
  const emailColor = useColorModeValue('#838383', 'gray.300');
  const [tabActive, setTabActive] = useState(0);
  const [isTabUpdateInfor, setIsTabUpdateInfor] = useState(false);
  const { userInfo } = useUserState();
  const { data, refetch } = useQueryMemberProfile({ enabled: CookieStorage.isAuthenticated() && !(userInfo?.role) });
  return (
    <Flex direction="column">
      <Header
        tokenCode={data?.data?.token}
        backgroundProfile={bgProfile}
        avatarImage={avatar4}
        name={data?.data?.fullname || data?.data?.username || userInfo?.fullname || userInfo?.username}
        email={data?.data?.email || userInfo?.email}
        balance={data?.data?.balance || userInfo?.balance}
        inviteCode={data?.data?.inviteCode || userInfo?.inviteCode}
        phone={data?.data?.phone || userInfo?.phone}
        role={data?.data?.role || userInfo?.role}
        tabs={[
          {
            name: 'TỔNG QUAN',
            icon: <FaCube w="100%" h="100%" color={tabActive !== 0 ? '#1951EE' : 'white'} />,
          },
          {
            name: 'BẢO MẬT',
            icon: <IoDocumentsSharp w="100%" h="100%" color={tabActive !== 1 ? '#1951EE' : 'white'} />,
          },
          {
            name: 'HOA HỒNG MỜI BẠN',
            icon: <FaPenFancy w="100%" h="100%" color={tabActive !== 2 ? '#1951EE' : 'white'} />,
          },
        ]}
        tabActive={tabActive}
        setTabActive={setTabActive}
      />
      {userInfo?.role !== Roles.ADMIN &&
        <Flex alignItems={"start"} px={'24px'} gap={2}>
          <Text fontSize={{ sm: 'sm', md: 'md' }} fontWeight="medium">
            Token:
          </Text>
          <Text
            gap={2}
            color={emailColor}
            overflow="hidden"
            whiteSpace="normal" // Cho phép xuống dòng
            wordBreak="break-all" // Ngắt dòng khi cần thiết
          >
            {data?.data?.token}
            <FaRegCopy
              cursor={'pointer'}
              onClick={() => {
                navigator?.clipboard?.writeText(data?.data?.token);
                toast.showMessageSuccess('Đã copy mã');
              }}
            />
          </Text>
        </Flex>
      }
      <Grid templateColumns={'1fr'} gap="22px">
        {tabActive === 0 &&
          (!isTabUpdateInfor ? (
            <PlatformSettings
              userInfo={data?.data}
              title={'Tổng quan'}
              subTitle={
                <Button variant="primary" px={6} onClick={() => setIsTabUpdateInfor(true)}>
                  <Text fontSize="md" fontWeight="bold" cursor="pointer">
                    Cập nhật thông tin
                  </Text>
                </Button>
              }
            />
          ) : (
            <UpdateInformationMember userInfo={data?.data} setIsTabUpdateInfor={setIsTabUpdateInfor} refetch={refetch} />
          ))}
        {tabActive === 1 && <ProfileInformation userInfo={data?.data} title={'Bảo mật'} setTabActive={setTabActive} setIsTabUpdateInfor={setIsTabUpdateInfor} />}
        {tabActive === 2 && <Conversations title={'Hoa hồng mời bạn'} />}
      </Grid>
    </Flex>
  );
}

export default Profile;
