import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { DepositStatusOption, ModalType } from 'constants/common';
import { toast } from 'components/Toast';
import SelectController from 'components/Form/SelectController';
import { useChangeStatusDepositMutation } from 'services/bank';
import InputController from 'components/Form/InputController';
import { useSendResponseMutation } from 'services/Tickets';

const ReplyTicket = ({ isOpen, historyDetail, onClose, refetch }) => {
  const cancelRef = React.useRef();

  const sendResponseMutation = useSendResponseMutation();

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      response: "",
    },
  });


  const handleSuccess = () => {
    toast.showMessageSuccess(`Cập nhật thành công`);
    refetch?.();
    onClose?.(ModalType.ChangeStatus);
  };

  const handleError = error => {
    toast.showMessageError(error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || `Cập nhật thất bại`);
    onClose?.(ModalType.ChangeStatus);
  };

  const onSubmit = values => {
      sendResponseMutation.mutate(
        {
            ticketId: historyDetail?._id,
          response: values.response,
        },
        {
          onSuccess: () => {
            handleSuccess();
          },
          onError: error => handleError(error),
        }
      );

      return;
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={() => {
        onClose(ModalType.ChangeStatus);
      }}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader textTransform="uppercase">Trả lời khách hàng</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
        <InputController type="textarea" control={control} name="response"  styleContainer={{ pt: '4' }} />
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            ref={cancelRef}
            onClick={() => {
              onClose(ModalType.ChangeStatus);
            }}
          >
            Hủy
          </Button>
          <Button
            fontSize="md"
            fontWeight="bold"
            variant="primary"
            ml={3}
            //   isLoading={createBankAccountMutation.isPending || updateServiceMutation.isPending}
            onClick={handleSubmit(onSubmit)}
          >
            Cập nhật
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ReplyTicket;
