import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const exportTransaction = (params, headers) => request.get('/api/v1/transactions/export',{ params, ...headers });
export const dashboard = () => request.get('/api/v1/statistics-dashboard',);


export const useExportTemplateTransactionMutation = (params, headers) => useMutation({ mutationFn: () => exportTransaction(params, headers) });


export const useQueryDashboard = (options = {}) => useQuery({ queryKey: ['DASH_BOARD'], queryFn: () => dashboard(), ...options });
