import {
  Box,
  Flex,
  IconButton,
  CloseButton,
  Image,
  Table,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  ModalCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import isEmpty from 'lodash/isEmpty';
import { formatDate, formatDateVN } from 'utils/helpers';
import { toast } from 'components/Toast';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';
import { renderLabelColor } from 'utils/styles';
import { ModalType, ROOT_API } from 'constants/common';
import { useCloseTicketMutation, useDeleteTicketMutation } from 'services/Tickets';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
const TicketTable = ({ data, refetch,handleUpdateItem }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const { userInfo } = useUserState();
  const deleteTicketMutation = useDeleteTicketMutation();
  const closeTicketMutation = useCloseTicketMutation();
  const handleDeleteTicket = async ticket => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa phiếu hỗ trợ này không?');

    if (confirmDelete) {
      deleteTicketMutation.mutate(
        { id: ticket?._id },
        {
          onSuccess: () => {
            toast.showMessageSuccess('Xóa phiếu hỗ trợ thành công');
            refetch?.();
          },
        }
      );
    }
  };
  const handleCloseTicket = async ticket => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn đóng phiếu hỗ trợ này không?');

    if (confirmDelete) {
      closeTicketMutation.mutate(
        { ticketId: ticket?._id },
        {
          onSuccess: () => {
            toast.showMessageSuccess('đóng phiếu hỗ trợ thành công');
            refetch?.();
          },
        }
      );
    }
  };
  const [selectedImage, setSelectedImage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleImageClick = imageSrc => {
    setSelectedImage(imageSrc);
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setIsOpen(false);
  };
  const columns = useMemo(
    () =>
      [
        userInfo?.role &&
          columnHelper.accessor('username', {
            header: 'Khánh hàng',
            cell: info => info.renderValue(),
          }),
        columnHelper.accessor('attachment', {
          header: 'Ảnh',
          // cell: info =>
          //   info.getValue() ? (
          //     <Image src={ROOT_API + '/' + info.getValue()} w="50px" h="50px" objectFit="contain" />
          //   ) : (
          //     <Image src="gibbresh.png" fallbackSrc="https://placehold.co/50?text=image&font=roboto" />
          //   ),
          cell: info => (
            <Box>
              {info.getValue() && (
                <Image
                  src={ROOT_API + '/' + info.getValue()}
                  w="50px"
                  h="50px"
                  objectFit="contain"
                  cursor="pointer"
                  onClick={() => handleImageClick(info.getValue() ? ROOT_API + '/' + info.getValue() : 'gibbresh.png')}
                />
              )}
            </Box>
          ),
        }),
        columnHelper.accessor('title', {
          header: 'Tiêu đề',
          cell: info => info.renderValue(),
        }),
        columnHelper.accessor('content', {
          header: 'Nội dung',
          cell: info => info.renderValue(),
        }),
        columnHelper.accessor('response', {
          header: 'Thông báo',
          cell: info => info.renderValue(),
        }),
        columnHelper.accessor('isClose', {
          header: 'Trạng thái',
          cell: info => (
            <Tag colorScheme={info.getValue() ? 'red' : 'gray'}>
              <TagLabel>{info.getValue() ? 'Đã đóng' : 'Đã gửi'}</TagLabel>
            </Tag>
          ),
        }),
        columnHelper.accessor('createdAt', {
          header: 'Ngày tạo',
          cell: info => formatDateVN(info.getValue()),
        }),
        userInfo?.role
          ? columnHelper.accessor('action', {
              header: 'Thao tác',
              cell: info => (
                <Flex alignItems="center" gap={1}>
                  <IconButton
                    bg="transparent"
                    onClick={() => {
                      handleCloseTicket(info?.row?.original);
                    }}
                  >
                    <CloseButton cursor="pointer" boxSize={4} />
                  </IconButton>
                  <IconButton
                    bg="transparent"
                    onClick={() => {
                      handleUpdateItem(info?.row?.original, ModalType.ChangeStatus);
                    }}
                  >
                    <EditIcon boxSize={4} />
                  </IconButton>
                  <IconButton
                    bg="transparent"
                    onClick={() => {
                      handleDeleteTicket(info?.row?.original);
                    }}
                  >
                    <DeleteIcon color="red.400" boxSize={4} />
                  </IconButton>
                </Flex>
              ),
            })
          : null,
      ].filter(item => !!item),
    [data, userInfo]
  );

  const table = useReactTable({
    data: data || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <>
      <Table>
        <Thead>
          {table.getHeaderGroups().map(headerGroup => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <Th key={header.id} w="120px" isTruncated>
                  {header.isPlaceholder ? null : (
                    <Box
                      cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                      onClick={header.column.getToggleSortingHandler()}
                      isTruncated
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: ' 🔼',
                        desc: ' 🔽',
                      }[header.column.getIsSorted()] ?? null}
                    </Box>
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {isEmpty(table.getRowModel().rows) ? (
            <Tr>
              <Td textAlign="center" colSpan={10}>
                Không có dữ liệu
              </Td>
            </Tr>
          ) : (
            table.getRowModel().rows.map(row => (
              <Tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <Td key={cell.id} isTruncated>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
      <Modal isOpen={isOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            {selectedImage && (
              <Zoom>
                <Image src={selectedImage} minW={'80%'} alt="Large View" />
              </Zoom>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TicketTable;
