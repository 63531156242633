import React, { useEffect } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import InputController from 'components/Form/InputController';
import { ModalType } from 'constants/common';
import { toast } from 'components/Toast';
import { CustomerApiFormValidate, WhitelistIPFormValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import { useCreateIpMutation } from 'services/whitelistIp';
import { useCreateCustomerApi } from 'services/user';

const FormIP = ({ companyDetail, isOpen, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const createCustomer = useCreateCustomerApi();
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(CustomerApiFormValidate),
  });

  const handleSuccess = message => {
    toast.showMessageSuccess(message);
    refetch?.();
    onClose(ModalType.Add);
  };
  const handleError = error => {
    toast.showMessageError(error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || `Cập nhật địa chỉ ip thất bại`);
  };
  const onSubmit = async values => {
    createCustomer.mutate(
      { ...values },
      {
        onSuccess: () => handleSuccess(`Tạo địa chỉ ip thành công`),
        onError: error => handleError(error),
      }
    );
  };

  useEffect(() => {
    if (!isEmpty(companyDetail)) {
      reset({
        title: companyDetail.title,
        price: companyDetail.price,
        description: companyDetail.description,
        id: companyDetail._id,
      });
    }
  }, [companyDetail]);
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Add);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'}>
          <AlertDialogHeader textTransform="uppercase">{isEmpty(companyDetail) ? 'Tạo' : 'Cập nhật'} khách hàng thuê API</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <InputController
              control={control}
              isRequired
              name="username"
              label="Username"
              styleContainer={{
                marginBottom: '16px',
              }}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Hủy
            </Button>
            <Button
              colorScheme="blue"
              ml={3}
              isLoading={createCustomer.isPending}
              onClick={handleSubmit(onSubmit)}
            >
              {'Tạo'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default FormIP;