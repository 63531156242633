import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Text,
    useColorModeValue,
    useDisclosure,
  } from '@chakra-ui/react';
  import React, { useEffect, useState } from 'react';
  import { CookieStorage } from 'utils/cookie-storage';
  import { useHistory } from 'react-router-dom';
  import { useMemo } from 'react';
  import { BrandFilter, LevelMemberKkOptionFilter, LevelMemberOptionFilter, ModalType } from 'constants/common';
  import SizeTable from './components/Table';
  // import CreateCategoryModal from './components/CreateCategoryModal';
  import Pagination from 'components/Pagination/Pagination';
  import { useListMemberMutation, useQueryGetListMember } from 'services/purchase-history';
  import AdjustCoinModal from './components/AdjustCoinModal';
  import { downloadFile } from 'utils/helpers';
  import { toast } from 'components/Toast';
import { useQueryGetListCustomerApi } from 'services/user';
import FormIP from './components/FormIPs';
  
  function Members() {
    const isLoggedIn = CookieStorage.isAuthenticated();
    const textColor = useColorModeValue('gray.700', 'white');
    const history = useHistory();
    const [sizeEditing, setSizeEditing] = useState(null);
    const [idMember, setIdMember] = useState();
    const [searchTitle, setSearchTitle] = useState('');
    const [kkLevel, setKkLevel] = useState();
    const [brand, setBrand] = useState();
    const [filter, setFilter] = useState({
      pageIndex: 0,
      pageSize: 20,
    });
  
    useEffect(() => {
      if (!isLoggedIn) {
        return history.push('/auth/sign-in');
      }
    }, [isLoggedIn, history]);
  
    const { isOpen: isCreateModalOpen, onOpen: onOpenCreateModal, onClose: onCloseCreateModal } = useDisclosure();
    const { isOpen: isChangeStatusModalOpen, onOpen: onOpenChangeStatusModal, onClose: onCloseChangeStatusModal } = useDisclosure();
    const { isOpen: isShowHistoryModal, onOpen: onOpenShowHistoryModal, onClose: onCloseShowHistoryModal } = useDisclosure();
    const {
      isOpen: isDetailAddressOrderMemberModal,
      onOpen: onOpenDetailAddressOrderMemberModal,
      onClose: onCloseDetailAddressOrderMemberModal,
    } = useDisclosure();
    const {
      isOpen: isOpenViewMemberDetailModal,
      onOpen: onOpenViewMemberDetailModal,
      onClose: onCloseViewMemberDetalModal,
    } = useDisclosure();
    const { isOpen: isOpenAdjustCoinModal, onOpen: onOpenAdjustCoinModal, onClose: onCloseAdjustCoinModal } = useDisclosure();
  
    const openModal = useMemo(
      () => ({
        [ModalType.Preview]: onOpenViewMemberDetailModal,
        [ModalType.Add]: onOpenCreateModal,
        [ModalType.Detail]: onOpenDetailAddressOrderMemberModal,
        [ModalType.ChangeStatus]: onOpenChangeStatusModal,
        [ModalType.Block]: onOpenShowHistoryModal,
        [ModalType.Adjust]: onOpenAdjustCoinModal,
      }),
      [
        onOpenCreateModal,
        onOpenChangeStatusModal,
        onOpenViewMemberDetailModal,
        onOpenDetailAddressOrderMemberModal,
        onOpenShowHistoryModal,
        onOpenAdjustCoinModal,
      ]
    );
    const closeModal = useMemo(
      () => ({
        [ModalType.Add]: onCloseCreateModal,
        [ModalType.Preview]: onCloseViewMemberDetalModal,
        [ModalType.ChangeStatus]: onCloseChangeStatusModal,
        [ModalType.Detail]: onCloseDetailAddressOrderMemberModal,
        [ModalType.Block]: onCloseShowHistoryModal,
        [ModalType.Adjust]: onCloseAdjustCoinModal,
      }),
      [onCloseCreateModal, onCloseChangeStatusModal, onCloseDetailAddressOrderMemberModal, onCloseShowHistoryModal, onCloseAdjustCoinModal]
    );
  
    const { data: members, refetch } = useQueryGetListCustomerApi({ ...filter, searchKeyword: filter.searchKeyword }, { enabled: isLoggedIn });
    const exportTemplateMemberMutation = useListMemberMutation(
      {
        ...filter,
        isExport: true,
        searchKeyword: searchTitle,
        kkLevel: kkLevel?.value,
        brand: brand?.value,
        responseType: 'arraybuffer',
      },
      {
        responseType: 'arraybuffer',
      }
    );
  
    const getDetailMemberById = useMemo(() => {
      return members?.data.find(member => member._id === idMember);
    }, [idMember]);
  
    const handleUpdateItem = (size, modalType) => {
      openModal?.[modalType]?.();
      setSizeEditing(size);
    };
  
    const handleCloseModal = modalType => {
      closeModal?.[modalType]?.();
      setSizeEditing(null);
      setIdMember(null);
    };
  
    const handleSearch = () => {
      setFilter({
        ...filter,
        searchKeyword: searchTitle,
        kkLevel: kkLevel?.value,
        brand: brand?.value,
      });
    };
  
    const handleReset = () => {
      setFilter({
        pageIndex: 0,
        pageSize: 100,
      });
      setKkLevel(null);
      setBrand(null);
      setSearchTitle('');
    };
  
    const handleShowMemberDetail = (idMember, modalType) => {
      openModal?.[modalType]?.();
      setIdMember(idMember);
    };
  
    const onDownloadTemplate = () => {
      exportTemplateMemberMutation.mutate(undefined, {
        onSuccess: response => {
          downloadFile(response, 'member-template');
          toast.showMessageSuccess('Tải mẫu khách hàng thành công');
        },
        onError: () => {
          toast.showMessageError('Tải mẫu khách hàng thất bại');
        },
      });
    };
    return (
      <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
        <Card p="16px" mb="24px" bg="#fff">
          <CardHeader p="12px 5px" mb="12px">
            <Flex justifyContent={'space-between'}>
              <Flex direction={'column'} w={'full'}>
                <Flex justifyContent={'space-between'} gap={'30px'}>
                  <Text fontSize="xl" color={textColor} fontWeight="bold">
                    Quản lý khách hàng
                  </Text>
                  <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} onClick={onOpenCreateModal}>
                      Thêm
                    </Button>
                </Flex>
                <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'20px'}>
                  <Stack>
                    <Flex alignItems={'center'} gap={'20px'} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                      <FormControl minWidth={{ base: 'full', sm: '300px' }}>
                        <FormLabel>Tìm kiếm khách hàng</FormLabel>
                        <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                      </FormControl>
                      {/* <FormControl>
                        <FormLabel h={'21px'}>Cấp độ HTTĐ</FormLabel>
                        <Select
                          placeholder="Chọn cấp độ"
                          isClearable
                          menuShouldBlockScroll
                          value={kkLevel}
                          onChange={e => {
                            setKkLevel(e);
                          }}
                          options={LevelMemberKkOptionFilter}
                        ></Select>
                      </FormControl>
                      <FormControl>
                        <FormLabel h={'21px'}>Nhà cái</FormLabel>
                        <Select
                          placeholder="Chọn nhà cái"
                          isClearable
                          menuShouldBlockScroll
                          value={brand}
                          onChange={e => {
                            setBrand(e);
                          }}
                          options={BrandFilter}
                        ></Select>
                      </FormControl> */}
                      <Button px={10} maxH="40px" alignSelf={'end'} onClick={handleReset}>
                        <Text fontSize="md" fontWeight="bold" cursor="pointer">
                          Cài lại
                        </Text>
                      </Button>
                      <Button variant="primary" px={10} maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                        <Text fontSize="md" fontWeight="bold" cursor="pointer">
                          Tìm kiếm
                        </Text>
                      </Button>
                    </Flex>
                  </Stack>
                </Flex>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody overflowX="auto">
            <Stack overflow={'auto'}>
              <SizeTable
                categorysData={members?.data || []}
                handleUpdateCategory={handleUpdateItem}
                refetch={refetch}
                handleShowMemberDetail={handleShowMemberDetail}
              />
            </Stack>
            <Flex justifyContent={'flex-end'}>
              <Pagination
                page={members?.pagination?.page}
                pageLength={members?.pagination?.pageSize}
                totalRecords={members?.pagination?.count}
                onPageChange={(page, pageLength) => {
                  setFilter({
                    ...filter,
                    pageSize: pageLength,
                    pageIndex: page - 1,
                  });
                }}
              />
            </Flex>
          </CardBody>
        </Card>
        {isOpenAdjustCoinModal && (
          <AdjustCoinModal isOpen={isOpenAdjustCoinModal} memberDetail={getDetailMemberById} onClose={handleCloseModal} refetch={refetch} />
        )}
          {isCreateModalOpen && (
          <FormIP companyDetail={sizeEditing} isOpen={isCreateModalOpen} onClose={handleCloseModal} refetch={refetch} />
        )}
      </Flex>
    );
  }
  
  export default Members;
  