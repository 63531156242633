import { DepositStatus, PHONE_SERVICE_STATUS } from 'constants/common';

export const renderLabelColor = color => {
  switch (color) {
    case PHONE_SERVICE_STATUS.FAILED:
    case DepositStatus.REJECTED:
      return 'red';
    case PHONE_SERVICE_STATUS.PENDING:
    case DepositStatus.PROCESSING:
      return 'gray';
    case PHONE_SERVICE_STATUS.SUCCESS:
    case DepositStatus.SUCCESS:
      return 'green';
    default:
      return 'gray';
  }
};
