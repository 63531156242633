import { Box, Flex, IconButton, Image, Table, Tbody, Td, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import isEmpty from 'lodash/isEmpty';
import { formatDate } from 'utils/helpers';
import { FORMAT_DATE } from 'constants/common';
import { toast } from 'components/Toast';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';
import { useDeletePhoneMutation } from 'services/phone';

const BankTable = ({ onOpen, productsData, refetch, setPhoneDetail }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const { userInfo } = useUserState();

  const deletePhoneMutation = useDeletePhoneMutation();

  const handleDeleteSize = product => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa số điện thoại này không?');
    if (!confirmDelete) {
      return;
    }

    deletePhoneMutation.mutate(
      { id: product?._id },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Xóa số điện thoại thành công');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Xóa số điện thoại thất bại');
          refetch?.();
        },
      }
    );
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('logo', {
        header: 'Ảnh',
        cell: info =>
          info.getValue() ? (
            <Image src={info.getValue()} w="100px" h="100px" objectFit="contain" />
          ) : (
            <Image src="gibbresh.png" fallbackSrc="https://placehold.co/50?text=image&font=roboto" />
          ),
      }),
      columnHelper.accessor('name', {
        header: 'Tên ngân hàng',
        cell: info => <Box textTransform="capitalize">{info.renderValue()}</Box>,
      }),
      columnHelper.accessor('bin', {
        header: 'Bin',
        cell: info => info.renderValue(),
      }),
      // columnHelper.accessor('action', {
      //   header: 'Thao tác',
      //   cell: info => (
      //     <Flex alignItems="center" gap={1}>
      //       <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'UPDATE_PRODUCT'}>
      //         <IconButton
      //           bg="transparent"
      //           onClick={() => {
      //             setPhoneDetail(info?.row?.original);
      //             onOpen();
      //           }}
      //         >
      //           <EditIcon cursor="pointer" boxSize={4} />
      //         </IconButton>
      //         <IconButton
      //           bg="transparent"
      //           onClick={() => {
      //             handleDeleteSize(info?.row?.original);
      //           }}
      //         >
      //           <DeleteIcon color="red.400" boxSize={4} />
      //         </IconButton>
      //       </AuthorizationComponentWrapper>
      //     </Flex>
      //   ),
      // }),
    ],
    [productsData]
  );

  const table = useReactTable({
    data: productsData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px" isTruncated>
                {header.isPlaceholder ? null : (
                  <Box
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                    isTruncated
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={10}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id} isTruncated>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default BankTable;
