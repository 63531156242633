import { isJsonString } from 'utils/helpers';
import { CookieStorage } from '../../utils/cookie-storage';
import { StorageKeys } from '../../constants/storage-keys';
import { checkAccessScreen } from 'utils/url';

/**
 *
 * WithAuthorization
 *
 */
export function authorizationOption(option, disabled = false, groupPermission, groupName) {
  const user = CookieStorage.getCookieData(StorageKeys.UserInfo);

  const userRoles = user?.role || [];
  const isAccess = checkAccessScreen({ userRoles, groupName, groupPermission });
  
  if (isAccess || !userRoles.length) {
    return option;
  } else {
    if (disabled) {
      return option;
    }
    return null;
  }
}

function AuthorizationComponentWrapper({ children, disabled = false, groupPermission, groupName }) {
  const user = CookieStorage.getCookieData(StorageKeys.UserInfo);

  const userRoles = user?.role || [];
  const isAccess = checkAccessScreen({ userRoles, groupName, groupPermission });
  
  if (isAccess || !userRoles.length) {
    return children;
  } else {
    if (disabled) {
      return children;
    }
    return null;
  }
}

export default AuthorizationComponentWrapper;
