import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Image,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { toast } from 'components/Toast';
import { DepositMethodOption } from 'constants/common';
import InputController from 'components/Form/InputController';
import SelectController from 'components/Form/SelectController';
import NumericInputController from 'components/Form/NumericInputController';
import { DepositFormValidate } from 'utils/validation';
import { useCreateBankTransactionMutation, useCreateQRCodeMomoMutation, useCreateQRCodeMutation } from 'services/bank';
import { useUserState } from 'context/UserContext';

export default function Deposit() {
  const textColor = useColorModeValue('gray.700', 'white');
  const currentUser = useUserState();
  const [qrCode, setQRCode] = useState(null);

  const createQRCodeMutation = useCreateBankTransactionMutation();
  const createQRCodeMomoMutation = useCreateQRCodeMomoMutation();

  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(DepositFormValidate),
    defaultValues: {
      amount: '',
      orderInfo: '',
      type: undefined,
    },
  });

  useEffect(() => {
    if (currentUser?.userInfo) {
      setValue('orderInfo', `${currentUser?.userInfo?.fullname || currentUser?.userInfo?.username} nap xu`);
    }
  }, [currentUser?.userInfo]);

  const onSubmit = values => {
    createQRCodeMutation.mutate(
      { amount: values?.amount },
      {
        onSuccess: res => {
          if (res?.data?.data?.checkoutUrl) {
            window.location.href = res?.data?.data?.checkoutUrl;
          }
          // setQRCode(res.data?.qrDataURL);
        },
      }
    );

    // if (values?.type?.value === 'bank') {
    //   createQRCodeMutation.mutate(
    //     { amount: values?.amount },
    //     {
    //       onSuccess: res => {
    //         if (res?.data?.data?.checkoutUrl) {
    //           window.location.href = res?.data?.data?.checkoutUrl;
    //         }
    //         // setQRCode(res.data?.qrDataURL);
    //       },
    //     }
    //   );

    //   return;
    // }

    // createQRCodeMomoMutation.mutate(
    //   { amount: values?.amount, orderInfo: values?.orderInfo, redirectUrl: window.location.href },
    //   {
    //     onSuccess: res => {
    //       window.location.href = res?.data?.payUrl;
    //     },
    //   }
    // );
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          {/* <Flex justifyContent={'space-between'}>
            <Flex direction={'column'}>
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                Nạp tiền
              </Text>
            </Flex>
          </Flex> */}
        </CardHeader>
        <CardBody overflowX="auto" p="0 5px">
          <Grid
            templateColumns={{ base: '1fr', md: 'repeat(5, 1fr)' }} // Sử dụng 1 cột cho màn mobile
            gap={4}
            minH="360px"
          >
            <GridItem colSpan={{ base: 1, md: 3 }}>
              {/* Chiều rộng cho mobile */}
              <NumericInputController control={control} name="amount" label="Số xu" isRequired />
              {/* <InputController type="textarea" control={control} name="orderInfo" label="Ghi chú" styleContainer={{ pt: '4' }} />
              <SelectController
                label="Phương thức nạp"
                name="type"
                placeholder="Chọn"
                control={control}
                options={DepositMethodOption}
                isRequired
                styleContainer={{ pt: '4' }}
                onChange={() => {
                  setQRCode(null);
                }}
              /> */}
              <Flex
                gap={2}
                direction={{ base: 'row', md: 'row' }} // Giữ hướng row cho cả hai màn
                mt={4}
              >
                <Button
                  width={{ base: '50%', md: 'auto' }} // Chiều rộng 50% cho mobile
                  maxH="40px"
                  onClick={() => {
                    reset();
                    setQRCode(null);
                  }}
                >
                  Cài lại
                </Button>
                <Button
                  width={{ base: '50%', md: 'auto' }} // Chiều rộng 50% cho mobile
                  fontSize="md"
                  fontWeight="bold"
                  variant="primary"
                  isLoading={createQRCodeMutation.isPending}
                  onClick={handleSubmit(onSubmit)}
                >
                  Nạp tiền
                </Button>
              </Flex>
            </GridItem>
            <GridItem colSpan={{ base: 1, md: 2 }}>
              {/* Chiều rộng cho mobile */}
              <Image src={qrCode} width="100%" /> {/* Đặt chiều rộng cho hình ảnh */}
            </GridItem>
          </Grid>
        </CardBody>
      </Card>
    </Flex>
  );
}
