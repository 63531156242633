import { Avatar, Box, Button, Flex, FormControl, FormLabel, Image, Text } from '@chakra-ui/react';
import { useRef } from 'react';

const FileSelectController = ({
  label,
  name,
  filePreview,
  error,
  imgHeight = '300px',
  imgWidth = '300px',
  showChangeBtn = true,
  showChooseBtn = true,
  isRequired,
  handleFileSelect,
  handleErrorFile,
  handleRemoveFile,
}) => {
  const inputRef = useRef();

  return (
    <FormControl pt={4} display="flex" alignItems="center" isRequired={isRequired}>
      <FormLabel minW="150px" margin="0">
        {label}
      </FormLabel>
      <Box alignItems="center">
        <Flex alignItems="center">
          <input
            type="file"
            hidden
            ref={inputRef}
            onChange={e => {
              handleFileSelect(name, e);
            }}
          />
          {!!filePreview ? (
            <Flex direction="column" alignItems="center" w="full">
              <Image boxSize="full" height={imgHeight} width={imgWidth} objectFit="cover" borderRadius="6px" src={filePreview} alt={name} />
              <Flex>
                {handleRemoveFile && (
                  <Text
                    ml={3}
                    fontSize="14px"
                    color="red.400"
                    cursor="pointer"
                    onClick={() => {
                      handleRemoveFile(name);
                    }}
                  >
                    Xoá
                  </Text>
                )}
                {showChangeBtn && (
                  <Text
                    ml={3}
                    fontSize="14px"
                    color="blue.400"
                    cursor="pointer"
                    onClick={() => {
                      handleErrorFile(name, null);
                      inputRef?.current?.click();
                    }}
                  >
                    Thay đổi
                  </Text>
                )}
              </Flex>
            </Flex>
          ) : (
            showChooseBtn && (
              <Button
                variant="primary"
                maxH="30px"
                onClick={() => {
                  handleErrorFile(name, null);
                  inputRef?.current?.click();
                }}
              >
                Chọn file
              </Button>
            )
          )}
        </Flex>
        {!!error && (
          <Text pt={1} color={'red.500'} fontSize="13px">
            {error}
          </Text>
        )}
      </Box>
    </FormControl>
  );
};

export default FileSelectController;
