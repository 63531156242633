import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import SizeTable from './components/Table';
import Pagination from 'components/Pagination/Pagination';
import { useListMemberMutation } from 'services/purchase-history';
import { downloadFile } from 'utils/helpers';
import { toast } from 'components/Toast';
import { useParams } from 'react-router-dom';
import { useQueryHistoryMember } from 'services/user';

function HistoryMember() {
  const isLoggedIn = CookieStorage.isAuthenticated();
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [searchTitle, setSearchTitle] = useState('');
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 100,
  });
  const params = useParams();
  const { id } = params || {};
  
  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/sign-in');
    }
  }, [isLoggedIn, history]);

  const { data: members, refetch } = useQueryHistoryMember({ ...filter,member:id }, { enabled: isLoggedIn});
    
  const exportTemplateMemberMutation = useListMemberMutation(
    {
      ...filter,
      isExport: true,
      searchKeyword: searchTitle,
      responseType: 'arraybuffer',
    },
    {
      responseType: 'arraybuffer',
    }
  );

 

  const handleUpdateItem = (size, modalType) => {
    openModal?.[modalType]?.();
    setSizeEditing(size);
  };


  const handleSearch = () => {
    setFilter({
      ...filter,
      searchKeyword: searchTitle,
    });
  };

  const handleReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 100,
    });
    setSearchTitle('');
  };

 

  const onDownloadTemplate = () => {
    exportTemplateMemberMutation.mutate(undefined, {
      onSuccess: response => {
        downloadFile(response, 'member-template');
        toast.showMessageSuccess('Tải mẫu hội viên thành công');
      },
      onError: () => {
        toast.showMessageError('Tải mẫu hội viên thất bại');
      },
    });
  };
  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'} w={'full'}>
              <Flex justifyContent={'space-between'} gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Lịch sử giao dịch xu của hội viên
                </Text>
                <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} isLoading={false} onClick={onDownloadTemplate}>
                  Xuất dữ liệu
                </Button>
              </Flex>
              <Flex justifyContent={'space-between'} alignItems={'start'} gap={'20px'} mt={'20px'}>
                <Stack>
                  <Flex alignItems={'center'} gap={'20px'} flexWrap="wrap">
                    <FormControl w="300px">
                      <FormLabel>Tìm kiếm SĐT</FormLabel>
                      <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                    </FormControl>
                    <Button px={10} maxH="40px" alignSelf={'end'} onClick={handleReset}>
                      <Text fontSize="md" fontWeight="bold" cursor="pointer">
                        Cài lại
                      </Text>
                    </Button>
                    <Button variant="primary" px={10} maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                      <Text fontSize="md" fontWeight="bold" cursor="pointer">
                        Tìm kiếm
                      </Text>
                    </Button>
                  </Flex>
                </Stack>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'}>
            <SizeTable
              categoryData={members?.data || []}
              // handleUpdateCategory={handleUpdateItem}
              refetch={refetch}
            />
          </Stack>
          <Flex justifyContent={'flex-end'}>
            <Pagination
              page={members?.pagination?.page}
              pageLength={members?.pagination?.pageSize}
              totalRecords={members?.pagination?.count}
              onPageChange={(page, pageLength) => {
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
              }}
            />
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default HistoryMember;
