import React from 'react';
import { Box, Button, Checkbox, Flex, Grid, GridItem, Heading, Image, Link, Text, useColorMode } from '@chakra-ui/react';
import { useHistory, Link as LinkRoute, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import queryString from 'query-string';

import InputController from 'components/Form/InputController';
import SelectController from 'components/Form/SelectController';
import { ContactChannelOptions } from 'constants/common';
import { useRegisterMutation } from 'services/user';
import { toast } from 'components/Toast';
import { RegisterFormValidate } from 'utils/validation';
import Logo from 'assets/img/logo.png';
import Card from 'components/Card/Card';
import bgAdmin from 'assets/img/landing.svg';
import { useCheckMobileScreen } from 'utils/use-device-mobile';

function SignUp() {
  const history = useHistory();
  const registerMutation = useRegisterMutation();
  const { search } = useLocation();
  const params = queryString.parse(search);
  const { colorMode } = useColorMode();
  const isDeviceMobile = useCheckMobileScreen();

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(RegisterFormValidate),
    defaultValues: {
      // username: '',
      // email: '',
      // password: '',
      // passwordConf: '',
      // contactChannel: undefined,
      // contactUsername: '',
      // countryCode: undefined,
      // phone: '',
    },
  });

  const onSubmit = values => {
    registerMutation.mutate(
      { ...values },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Đăng ký tài khoản thành công.');
          history.push('/auth/login');
        },
      }
    );
  };

  return (
    <Box minH="100vh" backgroundColor="#F6F8FF" m="auto" bgImage={colorMode === 'light' ? bgAdmin : 'none'}>
      <Box
        minH="100vh"
        w={{
          base: '100%',
          lg: '40%',
        }}
        backgroundColor="transparent"
        m="auto"
        // bgImage={colorMode === 'light' ? bgAdmin : 'none'}
      >
        <Grid w="100%" templateColumns="repeat(12, 1fr)">
          <GridItem w="100%" colSpan={{ base: '12' }} px={{ base: "0px", lg: '40px' }}>
            <Box marginY="10%">
              <Flex justifyContent="center">
                <Image src={Logo} alt="Logo" h="60px" />
              </Flex>
              <Card p={10} mt={10} rounded={10} bg="white">
                <Heading as="h3" textAlign="center" fontSize="24px" marginTop="20px" marginBottom="40px">
                  Đăng ký
                </Heading>
                <form>
                  {/* <InputController
                control={control}
                name="email"
                label="Email"
                isRequired
                styleContainer={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}
                styleBoxInput={{ flex: 1 }}
              /> */}
                  <InputController
                    control={control}
                    name="username"
                    label="Tên đăng nhập"
                    isRequired
                    styleContainer={!isDeviceMobile && { display: 'flex', alignItems: 'start', marginBottom: '15px' }}
                    styleBoxInput={{ flex: 1 }}
                    styleLabel={{ marginTop: '10px' }}
                    extendsComponent={
                      <Box marginTop="6px">
                        <Text fontSize="13px">Tối thiểu 5 kí tự.</Text>
                        <Text fontSize="13px">Chỉ bao gồm chữ thường, số và dấu chấm ".".</Text>
                        <Text fontSize="13px">Không bắt đầu và kết thúc bằng dấu chấm.</Text>
                      </Box>
                    }
                  />
                  <InputController
                    control={control}
                    name="fullname"
                    label="Tên đầy đủ"
                    isRequired
                    styleContainer={!isDeviceMobile && { display: 'flex', alignItems: 'start', marginBottom: '15px' }}
                    styleBoxInput={{ flex: 1 }}
                    styleLabel={{ marginTop: '10px' }}
                    extendsComponent={
                      <Box marginTop="6px">
                        <Text fontSize="13px">Tối thiểu 5 kí tự.</Text>
                      </Box>
                    }
                  />
                  <InputController
                    control={control}
                    name="email"
                    label="Email"
                    isRequired
                    styleContainer={!isDeviceMobile && { display: 'flex', alignItems: 'start', marginBottom: '15px' }}
                    styleBoxInput={{ flex: 1 }}
                    styleLabel={{ marginTop: '10px' }}
                  />
                   <InputController
                    control={control}
                    name="phone"
                    label="Số điện thoại"
                    type='number'
                    styleContainer={!isDeviceMobile && { display: 'flex', alignItems: 'start', marginBottom: '15px' }}
                    styleBoxInput={{ flex: 1 }}
                    styleLabel={{ marginTop: '10px' }}
                  />
                  <InputController
                    control={control}
                    type="password"
                    name="password"
                    label="Mật khẩu"
                    isRequired
                    styleContainer={!isDeviceMobile && { display: 'flex', alignItems: 'center', marginBottom: '15px' }}
                    styleBoxInput={{ flex: 1 }}
                    styleLabel={{ marginTop: '10px' }}
                  />
                  <InputController
                    control={control}
                    type="password"
                    name="passwordConf"
                    label="Xác nhận mật khẩu"
                    isRequired
                    styleContainer={!isDeviceMobile && { display: 'flex', alignItems: 'center', marginBottom: '15px' }}
                    styleBoxInput={{ flex: 1 }}
                    styleLabel={{ marginTop: '10px' }}
                  />
                  {/*  <SelectController
                control={control}
                name="contactChannel"
                label="Kênh liên lạc"
                options={ContactChannelOptions}
                isRequired
                styleContainer={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}
                styleBoxInput={{ flex: 1 }}
              />
              <InputController
                control={control}
                name="contactUsername"
                label="Tài khoản liên lạc"
                isRequired
                styleContainer={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}
                styleBoxInput={{ flex: 1 }}
              /> */}
                </form>
                <Box textAlign="center" paddingX="24px">
                  <Checkbox fontSize="12px" flex alignItems={'start'} mt={2}>
                    Tôi đồng ý với các điều khoản trong
                    <Link href="#" target="_blank" color="#337ab7">
                      &nbsp;Thoả thuận sử dụng
                    </Link>
                    &nbsp;và&nbsp;
                    <Link href="#" target="_blank" color="#337ab7">
                      Chính sách bảo vệ dữ liệu cá nhân.
                    </Link>
                  </Checkbox>
                </Box>
                <Box paddingTop="15px">
                  <Button colorScheme="blue" w="100%" onClick={handleSubmit(onSubmit)}>
                    Đăng ký
                  </Button>
                </Box>
                <Box paddingY="15px" textAlign="center">
                  Bạn đã có tài khoản&nbsp;
                  <LinkRoute to="/auth/login" style={{ color: '#337ab7' }}>
                    Đăng nhập
                  </LinkRoute>
                </Box>
              </Card>
            </Box>
          </GridItem>
        </Grid>
      </Box>
    </Box>
  );
}

export default SignUp;
