import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getListTicketForMember = params => request.get('/api/v1/my-tickets/', { params });
export const getListTicketForBo = params => request.get('/api/v1/tickets', { params });
export const createTicket = data => request.post('/api/v1/tickets/create', data);
export const deleteTicket = data => request.post('/api/v1/tickets/delete', data);
export const closeTicket = data => request.post('/api/v1/tickets/close', data);
export const sendResponse = data => request.post('/api/v1/tickets/send-response', data);

export const uploadAttachmentTicket = async data => {
  return await request.post('/api/v1/services/upload-attachment', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

// Mutation
export const useCreateTicketMutation = () => useMutation({ mutationFn: createTicket });
export const useDeleteTicketMutation = () => useMutation({ mutationFn: deleteTicket });
export const useCloseTicketMutation = () => useMutation({ mutationFn: closeTicket });
export const useSendResponseMutation = () => useMutation({ mutationFn: sendResponse });

// Query
export const useGetListTicketForMember = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_LIST_TICKET_FOR_MEMBER', params], queryFn: () => getListTicketForMember(params), ...options });

export const useGetListTicketForBO = (params = {}, options = {}) =>
    useQuery({ queryKey: ['GET_LIST_TICKET_FOR_BO', params], queryFn: () => getListTicketForBo(params), ...options });
  