import { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Flex, FormControl, FormLabel, Input, Stack, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Pagination from 'components/Pagination/Pagination';
import DepositHistoryTable from './components/Table';
import { toast } from 'components/Toast';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';
import { useCancelTransactionMutation, useQueryGetDepositHistory, useQueryGetDepositHistoryAdmin } from 'services/bank';
import { ModalType, Roles } from 'constants/common';
import ChangeStatusModal from './components/ChangeStatusModal';
import { downloadFile } from 'utils/helpers';
import { useExportTemplateTransactionMutation } from 'services/transaction';

export default function DepositHistory() {
  const history = useHistory();
  const { search } = useLocation();
  const params = queryString.parse(search);
  const { status, orderCode } = params || {};

  const textColor = useColorModeValue('gray.700', 'white');
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 100,
  });
  const [searchTitle, setSearchTitle] = useState('');
  const [historyDetail, setHistoryDetail] = useState(null);
  const { userInfo } = useUserState();

  const { isOpen: isChangeStatusModalOpen, onOpen: onOpenChangeStatusModal, onClose: onCloseChangeStatusModal } = useDisclosure();
  const openModal = useMemo(
    () => ({
      [ModalType.ChangeStatus]: onOpenChangeStatusModal,
    }),
    [onOpenChangeStatusModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.ChangeStatus]: onCloseChangeStatusModal,
    }),
    [onCloseChangeStatusModal]
  );

  const { data: historyData, refetch: refetchHistoryData } = useQueryGetDepositHistory(filter, { enabled: !userInfo?.role });
  const { data: adminHistoryData, refetch } = useQueryGetDepositHistoryAdmin(filter, { enabled: userInfo?.role === Roles.ADMIN });
  const exportTemplateTransactionMutation = useExportTemplateTransactionMutation(
    {},
    {
      responseType: 'arraybuffer',
    }
  );
  const cancelTransactionMutation = useCancelTransactionMutation();

  useEffect(() => {
    if (!!status && !!orderCode && status === 'CANCELLED') {
      cancelTransactionMutation.mutate(
        { orderCode },
        {
          onSuccess: () => {
            history.replace({ pathname: '/admin/deposit-history' });
            refetchHistoryData();
          },
        }
      );
    }
  }, [status, orderCode]);

  const onFilter = () => {
    setFilter({
      ...filter,
      searchKeyword: searchTitle,
    });
  };

  const handleUpdateItem = (size, modalType) => {
    openModal?.[modalType]?.();
    setHistoryDetail(size);
  };

  const handelCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setHistoryDetail(null);
  };

  const onReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 100,
    });
    setSearchTitle('');
  };

  const onDownloadTemplate = () => {
    exportTemplateTransactionMutation.mutate(undefined, {
      onSuccess: response => {
        downloadFile(response, 'transaction-template');
        toast.showMessageSuccess('Tải mẫu giao dịch thành công');
      },
      onError: err => {
        console.log(err);

        toast.showMessageError('Tải mẫu giao dịch thất bại');
      },
    });
  };

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
        <Card p="16px" mb="24px" bg="#fff">
          <CardHeader p="12px 5px" mb="12px">
            <Flex justifyContent={'space-between'} gap={'30px'}>
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                Quản lý khách hàng
              </Text>
              <Button fontSize="md" fontWeight="bold" variant="primary" isLoading={false} onClick={onDownloadTemplate}>
                Export
              </Button>
            </Flex>
            <Flex justifyContent={'space-between'}>
              <Flex direction={'column'}>
                <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} my={'20px'}>
                  <Stack>
                    <Flex gap={'20px'} flexDirection={'column'} flexWrap="wrap">
                      <Flex
                        alignItems={'end'}
                        justifyContent={'start'}
                        gap={5}
                        flexWrap={{ base: 'wrap', md: 'nowrap' }} // Xếp chồng trên mobile
                      >
                        <FormControl w={{ base: '100%', md: '200px' }}>
                          {' '}
                          {/* Chiều rộng 100% cho mobile */}
                          <FormLabel>Tìm kiếm</FormLabel>
                          <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                        </FormControl>
                        <Flex
                          gap={2}
                          direction={{ base: 'row', md: 'row' }}
                          w={{ base: '100%', md: 'auto' }} // Chiều rộng 100% cho mobile
                        >
                          <Button
                            width={{ base: '50%', md: 'auto' }} // Chiều rộng 50% cho mobile
                            maxH="40px"
                            onClick={onReset}
                          >
                            <Text fontSize="md" fontWeight="bold" cursor="pointer">
                              Cài lại
                            </Text>
                          </Button>
                          <Button
                            width={{ base: '50%', md: 'auto' }} // Chiều rộng 50% cho mobile
                            variant="primary"
                            maxH="40px"
                            px={5}
                            onClick={onFilter}
                          >
                            <Text fontSize="md" fontWeight="bold" cursor="pointer">
                              Tìm kiếm
                            </Text>
                          </Button>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Stack>
                </Flex>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody overflowX="auto">
            <Stack overflow={'auto'}>
              <DepositHistoryTable historyData={adminHistoryData?.data || historyData?.data || []} handleUpdateItem={handleUpdateItem} />
            </Stack>
            <Flex justifyContent={'flex-end'}>
              {(adminHistoryData?.data || !isEmpty(historyData?.data)) && (
                <Pagination
                  page={adminHistoryData?.pagination?.page}
                  pageLength={adminHistoryData?.pagination?.pageSize || historyData?.pagination?.pageSize}
                  totalRecords={adminHistoryData?.pagination?.count || historyData?.pagination?.count}
                  onPageChange={(page, pageLength) => {
                    setFilter({
                      ...filter,
                      pageSize: pageLength,
                      pageIndex: page - 1,
                    });
                  }}
                />
              )}
            </Flex>
          </CardBody>
        </Card>
      </Flex>
      {isChangeStatusModalOpen && (
        <ChangeStatusModal isOpen={isChangeStatusModalOpen} historyDetail={historyDetail} onClose={handelCloseModal} refetch={refetch} />
      )}
    </>
  );
}
