import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getListIp = params => request.get('/api/v1/ips', { params });
export const createIp = data => request.post('/api/v1/ips', data);
export const deleteIp = data => request.post('/api/v1/ips/delete', data);
export const dropIp = data => request.post('/api/v1/ips/drop-collection', data);

// Mutation
export const useCreateIpMutation = () => useMutation({ mutationFn: createIp });
export const useDeleteIpMutation = () => useMutation({ mutationFn: deleteIp });
export const useDropIpMutation = () => useMutation({ mutationFn: dropIp });

// Query
export const useQueryGetListIp = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_LIST_IP', params], queryFn: () => getListIp(params), ...options });
