import { Box, Flex, IconButton, Link, Table, Tag, TagLabel, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { FORMAT_DATE } from 'constants/common';
import { toast } from 'components/Toast';
import { formatCurrencyToVND, formatDate, formatDateVN } from 'utils/helpers';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';
import { useDeleteServiceMutation, useSendRequestZaloMutation } from 'services/service';
import { renderLabelColor } from 'utils/styles';
import { FaRegCopy } from 'react-icons/fa';
import { BsFillSendFill } from 'react-icons/bs';

const SizeTable = ({ categoryData, handleUpdateCategory, refetch }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const deleteServiceMutation = useDeleteServiceMutation();
  const sendRequestZaloMutation = useSendRequestZaloMutation();
  const { userInfo } = useUserState();

  const handleRowClick = (ticket, type) => {
    handleUpdateCategory(ticket, type);
  };

  const handleDeleteSize = async category => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa dịch vụ này không?');
    if (!confirmDelete) {
      return;
    }
    deleteServiceMutation.mutate(
      { id: category?._id },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Xóa dịch vụ thành công');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Xóa dịch vụ không thành công');
          refetch?.();
        },
      }
    );
  };

  const handleSendRequestZalo = async service => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn gửi đề nghị để lấy mã zalo này không?');
    if (!confirmDelete) {
      return;
    }
    sendRequestZaloMutation.mutate(
      { phoneServiceId: service?._id },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Gửi đề nghị để lấy mã zalo thành công');
          refetch?.();
        },
      }
    );
  }
  
  const onActionProduct = (productId, title, actionApi) => {
    actionApi.mutate(
      { id: productId },
      {
        onSuccess: () => {
          toast.showMessageSuccess(`${title} thành công`);
          refetch?.();
        },
        onError: () => {
          toast.showMessageError(`${title} thất bại`);
          refetch?.();
        },
      }
    );
  };

  const columns = useMemo(
    () => [
      // columnHelper.accessor('thumbnails', {
      //   header: 'Ảnh',
      //   cell: info =>
      //     info.getValue() ? (
      //       <Image src={ROOT_API + '/' + info.getValue()} w="50px" h="50px" objectFit="contain" />
      //     ) : (
      //       <Image src="gibbresh.png" fallbackSrc="https://placehold.co/50?text=image&font=roboto" />
      //     ),
      // }),
      columnHelper.accessor('phoneNumber', {
        header: 'Số điện thoại',
        cell: info => info.getValue() || '--',
      }),
      columnHelper.accessor('service', {
        header: 'Dịch vụ',
        cell: info => info.getValue()?.title || '--',
      }),
      columnHelper.accessor('code', {
        header: 'Mã',
        cell: info =>
          info.getValue() ? (
            <Flex alignItems={"center"} gap={2}>
              <Text>{info.getValue()}</Text>
              <FaRegCopy
                cursor={'pointer'}
                onClick={() => {
                  navigator?.clipboard?.writeText(info.getValue());
                  toast.showMessageSuccess('Đã copy mã');
                }}
              />
            </Flex>
          ) : (
            '--'
          ),
      }),
      // columnHelper.accessor('content', {
      //   header: 'Nội dung',
      //   cell: info => info.getValue() || '--',
      // }),
      columnHelper.accessor('price', {
        header: 'Xu',
        cell: info => formatCurrencyToVND(info.getValue()),
      }),
      columnHelper.accessor('status', {
        header: 'Trạng thái',
        cell: info => (
          <Tag colorScheme={renderLabelColor(info.getValue())}>
            <TagLabel>{info.getValue()}</TagLabel>
          </Tag>
        ),
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày tạo',
        cell: info => <Text whiteSpace={'nowrap'}>{formatDateVN(info.row.original.createdAt, FORMAT_DATE)}</Text>,
      }),
      // columnHelper.accessor('action', {
      //   header: 'Thao tác',
      //   cell: info => (
      //     <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'UPDATE_PRODUCT_CATEGORY'}>
      //       <Flex alignItems="center" gap={1}>
      //         {
      //           info?.row?.original?.service?.store === 'zalo' && info?.row?.original?.status === 'SUCCESS' &&
      //           <Flex gap={2}
      //           onClick={() => {
      //             handleSendRequestZalo(info?.row?.original);
      //           }}
      //           cursor={'pointer'}
      //         >
      //           <Link color="teal.400" textDecoration="underline"
      //             _hover={{ textDecoration: 'underline', color: 'teal.300' }}
      //             >
      //             Gửi SMS
      //           </Link>
      //           <BsFillSendFill size={16} />
      //         </Flex>
      //         }
      //         {/* <IconButton
      //           bg="transparent"
      //           onClick={() => {
      //             handleRowClick(info?.row?.original, ModalType.Add);
      //           }}
      //         >
      //           <EditIcon cursor="pointer" boxSize={4} />
      //         </IconButton> */}
      //         {/* <IconButton
      //         bg="transparent"
      //         onClick={() => {
      //           history.push(`/admin/category/${info?.row?.original?._id}/size`);
      //           }}
      //           >
      //         <TbTextResize size={16} />
      //         </IconButton>*/}
      //         {/* <IconButton
      //           bg="transparent"
      //           onClick={() => {
      //             handleDeleteSize(info?.row?.original);
      //           }}
      //         >
      //           <DeleteIcon color="red.400" boxSize={4} />
      //         </IconButton> */}
      //       </Flex>
      //     </AuthorizationComponentWrapper>
      //   ),
      // }),
    ],
    [categoryData]
  );

  const table = useReactTable({
    data: categoryData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px" isTruncated>
                {header.isPlaceholder ? null : (
                  <Box cursor={header.column.getCanSort() ? 'pointer' : 'default'} onClick={header.column.getToggleSortingHandler()}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={7}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default SizeTable;
