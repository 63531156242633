import { Box, Table, Tbody, Td, Text, Th, Thead, Tr, Flex, Tooltip, Tag, TagLabel, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { IconButton } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { FORMAT_DATE } from 'constants/common';
import moment from 'moment';
import { toast } from 'components/Toast';
import { AiFillDelete } from 'react-icons/ai';
import { useDeleteIpMutation } from 'services/whitelistIp';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';
import { MdOutlineCurrencyExchange } from 'react-icons/md';
import { FaHistory } from 'react-icons/fa';
import { DeleteIcon } from '@chakra-ui/icons';
import { useDeleteCustomerApi } from 'services/user';
import { ModalType } from 'constants/common';
import { formatCurrencyToVND } from 'utils/helpers';
const SizeTable = ({ categorysData, refetch,handleShowMemberDetail }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const deleteCustomerApiMutation = useDeleteCustomerApi();

  const { userInfo } = useUserState();
  const handleViewClick = (idMember, ModalType) => {
    handleShowMemberDetail(idMember, ModalType);
  };

  // const handleDelete = async data => {
  //   const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa khách hàng này không?');

  //   if (confirmDelete) {
  //     deleteCustomerApiMutation.mutate(
  //       { username: data?.username },
  //       {
  //         onSuccess: () => {
  //           toast.showMessageSuccess('Xóa khách hàng thành công');
  //           refetch?.();
  //         },
  //       }
  //     );
  //   }
  // };
  const columns = useMemo(
    () => [
      columnHelper.accessor('username', {
        header: 'Tên tài khoản',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('status', {
        header: 'Trạng thái',
        cell: info => (
          <Tag borderRadius="full" variant="solid" colorScheme={info.getValue() !== 'OFFLINE' ? 'green' : 'gray'}>
            <TagLabel>{info.getValue()}</TagLabel>
          </Tag>
        ),
      }),
      columnHelper.accessor('statusBlock', {
        header: 'Trạng thái chặn',
        cell: info => info.getValue() || '--',
      }),
      columnHelper.accessor('balance', {
        header: 'Số dư',
        cell: info => <Text>{formatCurrencyToVND(info.getValue())}</Text>,
      }),
      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => (
          <Flex alignItems="center" margin={'auto'}>
            <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'VIEW_DETAIL_MEMBER'}>
              <IconButton bg="transparent" onClick={() => handleViewClick(info?.row?.original?._id, ModalType.Adjust)}>
                <MdOutlineCurrencyExchange cursor="pointer" boxSize={4} />
              </IconButton>
            </AuthorizationComponentWrapper>
            {/* <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton bg="transparent" isActive={isOpen} as={IconButton}>
                    <IconButton bg="transparent" onClick={() => handleViewClick(info?.row?.original?._id, ModalType.Block)}>
                      <FaHistory cursor="pointer" boxSize={4} />
                    </IconButton>
                  </MenuButton>
                  <MenuList>
                    <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'VIEW_DETAIL_MEMBER'}>
                      <MenuItem onClick={() => handleViewClick(info?.row?.original?._id, ModalType.Preview)}>Chi tiết khách hàng</MenuItem>
                    </AuthorizationComponentWrapper>
                  </MenuList>
                </>
              )}
            </Menu> */}
            {/* <IconButton
                    bg="transparent"
                    onClick={() => {
                      handleDelete(info?.row?.original);
                    }}
                  >
                    <DeleteIcon color="red.400" boxSize={4} />
                  </IconButton> */}
          </Flex>
        ),
      }),
    ],
    [categorysData]
  );
  const table = useReactTable({
    data: categorysData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box
                    isTruncated
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={6}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default SizeTable;