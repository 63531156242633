import { useMemo, useRef, useState } from 'react';
import { Button, Flex, FormControl, FormLabel, Input, Stack, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Pagination from 'components/Pagination/Pagination';
import { toast } from 'components/Toast';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';
import { useQueryGetDepositHistory, useQueryGetDepositHistoryAdmin } from 'services/bank';
import { ModalType, Roles, TicketOption } from 'constants/common';
import { useHistory } from 'react-router-dom';
import TicketTable from './components/Table';
import { useGetListTicketForBO, useGetListTicketForMember } from 'services/Tickets';
import { Select } from 'chakra-react-select';
import ReplyTicket from './components/ReplyTicket';
export default function TicketsMember() {
  const history = useHistory();
  const textColor = useColorModeValue('gray.700', 'white');
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 100,
  });
  const [searchTitle, setSearchTitle] = useState('');
  const [isClose, setIsClose] = useState(null);
  const [historyDetail, setHistoryDetail] = useState(null);
  const { userInfo } = useUserState();
  const { isOpen: isChangeStatusModalOpen, onOpen: onOpenChangeStatusModal, onClose: onCloseChangeStatusModal } = useDisclosure();
  const openModal = useMemo(
    () => ({
      [ModalType.ChangeStatus]: onOpenChangeStatusModal,
    }),
    [onOpenChangeStatusModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.ChangeStatus]: onCloseChangeStatusModal,
    }),
    [onCloseChangeStatusModal]
  );

  const { data: ticketData } = useGetListTicketForMember(filter, { enabled: !userInfo?.role });
  const { data: adminTicketData, refetch } = useGetListTicketForBO(filter, { enabled: userInfo?.role === Roles.ADMIN });

  const onFilter = () => {
    setFilter({
      ...filter,
      searchKeyword: searchTitle,
      isClose: userInfo?.role === Roles.ADMIN && isClose?.value
    });
  };

  const handleUpdateItem = (size, modalType) => {
    openModal?.[modalType]?.();
    setHistoryDetail(size);
  };

  const handelCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setHistoryDetail(null);
  };

  const onReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 100,
    });
    setSearchTitle('');
    setIsClose(null)
  };

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
        <Card p="16px" mb="24px" bg="#fff">
          <CardHeader p="12px 5px" mb="12px">
            <Flex justifyContent={'space-between'}>
              <Flex direction={'column'} width={'100%'}>
                {userInfo?.role !== Roles.ADMIN &&
                  <Flex justifyContent={'flex-end'} >
                    <Button onClick={() => history.push('/admin/tickets/create')} variant="primary">
                      <Text fontSize="md" fontWeight="bold" cursor="pointer">
                        Tạo phiếu
                      </Text>
                    </Button>
                  </Flex>
                }
                <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} my={'20px'}>
                  <Stack>
                    <Flex
                      gap={'20px'}
                      flexDirection={'column'}
                      flexWrap={{ base: 'wrap', md: 'nowrap' }}
                    >
                      <Flex
                        alignItems={'end'}
                        justifyContent={'start'}
                        gap={5}
                        flexWrap={{ base: 'wrap', md: 'nowrap' }} // Xếp chồng trên mobile
                      >
                        <FormControl w={{ base: '100%', md: '200px' }}> {/* Chiều rộng 100% cho mobile */}
                          <FormLabel>Tìm kiếm</FormLabel>
                          <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                        </FormControl>

                        {userInfo?.role === Roles.ADMIN && (
                          <FormControl w={{ base: '100%', md: '250px' }}> {/* Chiều rộng 100% cho mobile */}
                            <FormLabel h={'21px'}>Trạng thái</FormLabel>
                            <Select
                              placeholder="Chọn trạng thái"
                              isClearable
                              menuShouldBlockScroll
                              value={isClose}
                              onChange={e => {
                                setIsClose(e);
                              }}
                              options={TicketOption}
                            />
                          </FormControl>
                        )}

                        <Flex
                          gap={2}
                          direction={{ base: 'row', md: 'row' }}
                          w={{ base: '100%', md: 'auto' }} // Chiều rộng 100% cho mobile
                        >
                          <Button
                            width={{ base: '50%', md: 'auto' }} // Chiều rộng 50% cho mobile
                            maxH="40px"
                            onClick={onReset}
                          >
                            <Text fontSize="md" fontWeight="bold" cursor="pointer">
                              Cài lại
                            </Text>
                          </Button>
                          <Button
                            width={{ base: '50%', md: 'auto' }} // Chiều rộng 50% cho mobile
                            variant="primary"
                            maxH="40px"
                            px={5}
                            onClick={onFilter}
                          >
                            <Text fontSize="md" fontWeight="bold" cursor="pointer">
                              Tìm kiếm
                            </Text>
                          </Button>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Stack>
                </Flex>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody overflowX="auto">
            <Stack overflow={'auto'}>
              <TicketTable data={adminTicketData?.data || ticketData?.data || []} handleUpdateItem={handleUpdateItem} refetch={refetch} />
            </Stack>
            <Flex justifyContent={'flex-end'}>
              {(adminTicketData?.data || !isEmpty(ticketData?.data)) && (
                <Pagination
                  page={adminTicketData?.pagination?.page}
                  pageLength={adminTicketData?.pagination?.pageSize || ticketData?.pagination?.pageSize}
                  totalRecords={adminTicketData?.pagination?.count || ticketData?.pagination?.count}
                  onPageChange={(page, pageLength) => {
                    setFilter({
                      ...filter,
                      pageSize: pageLength,
                      pageIndex: page - 1,
                    });
                  }}
                />
              )}
            </Flex>
          </CardBody>
        </Card>
      </Flex>
      {/* {isChangeStatusModalOpen && (
        <ChangeStatusModal isOpen={isChangeStatusModalOpen} historyDetail={historyDetail} onClose={handelCloseModal} refetch={refetch} />
      )} */}
            {isChangeStatusModalOpen && (
        <ReplyTicket isOpen={isChangeStatusModalOpen} historyDetail={historyDetail} onClose={handelCloseModal} refetch={refetch} />
      )}
    </>
  );
}
