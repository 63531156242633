import {
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Flex,
  Tag,
  TagLabel,
  Code,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { IconButton } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { ModalType } from 'constants/common';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';
import { MdDetails, MdOutlineCurrencyExchange } from 'react-icons/md';
import { FaClipboardList, FaHistory } from 'react-icons/fa';
import { formatCurrencyToVND } from 'utils/helpers';
import { useResetDefaultPassword } from 'services/user';
import { toast } from 'components/Toast';
import { EditIcon } from '@chakra-ui/icons';
import { BiDetail } from 'react-icons/bi';

const SizeTable = ({ categorysData, handleUpdateCategory, refetch, handleShowMemberDetail }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const history = useHistory();
  const { userInfo } = useUserState();

  const resetDefaultPasswordMutation=useResetDefaultPassword()

  const handleViewClick = (idMember, ModalType) => {
    handleShowMemberDetail(idMember, ModalType);
  };

  const handleResetPassword=(username)=>{
    const confirmDelete = window.confirm(`Bạn có chắc chắn muốn đặt lại mật khẩu mặc định cho khách hàng '${username}' không?`);
    if (!confirmDelete) {
      return;
    }

    resetDefaultPasswordMutation.mutate({
      username
    },{
      onSuccess:()=>{
        toast.showMessageSuccess('Đặt lại mật khẩu mặc định thành công!');
      },
      onError:()=>{
        toast.showMessageError('Đặt lại mật khẩu mặc định thất bại!')
      }
    })
  }

  const columns = useMemo(
    () => [
      columnHelper.accessor('username', {
        header: 'Tên tài khoản',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('fullname', {
        header: 'Tên đầy đủ',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('phone', {
        header: 'Số điện thoại',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('email', {
        header: 'Email',
        cell: info => info.getValue() || '--',
      }),
      // columnHelper.accessor('status', {
      //   header: 'Trạng thái',
      //   cell: info => (
      //     <Tag borderRadius="full" variant="solid" colorScheme={info.getValue() !== 'OFFLINE' ? 'green' : 'gray'}>
      //       <TagLabel>{info.getValue()}</TagLabel>
      //     </Tag>
      //   ),
      // }),
      // columnHelper.accessor('statusBlock', {
      //   header: 'Trạng thái chặn',
      //   cell: info => info.getValue() || '--',
      // }),
      columnHelper.accessor('balance', {
        header: 'Số dư',
        cell: info =>formatCurrencyToVND(info.getValue()) || '--',
      }),
      // columnHelper.accessor('inviteCode', {
      //   header: 'Mã giới thiệu',
      //   cell: info => (info.getValue() ? <Code fontSize={20}>{info.getValue()}</Code> : '--'),
      // }),
      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => (
          <Flex alignItems="center" margin={'auto'}>
            <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'VIEW_DETAIL_MEMBER'}>
            <IconButton
                bg="transparent"
                onClick={() => {
                  history.push(`/admin/member/${info?.row?.original?._id}`)
                }}
              >
                <FaClipboardList cursor="pointer" boxSize={4} />
              </IconButton>
            </AuthorizationComponentWrapper>
            <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'VIEW_DETAIL_MEMBER'}>
              <IconButton bg="transparent" onClick={() => handleViewClick(info?.row?.original?._id, ModalType.Adjust)}>
                <MdOutlineCurrencyExchange cursor="pointer" boxSize={4} />
              </IconButton>
            </AuthorizationComponentWrapper>
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton bg="transparent" isActive={isOpen} as={IconButton}>
                    <IconButton bg="transparent" onClick={() => handleViewClick(info?.row?.original?._id, ModalType.Block)}>
                      <EditIcon cursor="pointer" boxSize={4} />
                    </IconButton>
                  </MenuButton>
                  <MenuList>
                    {/* <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'VIEW_HISTORY_RECHARGE_BET_MEMBER'}>
                      <MenuItem
                        onClick={() => {
                          history.push(`/admin/members/${info?.row?.original?._id}/histories`);
                        }}
                      >
                        Lịch sử nạp, cược
                      </MenuItem>
                    </AuthorizationComponentWrapper>
                    <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'VIEW_HISTORY_COLLECT_COIN_MEMBER'}>
                      <MenuItem
                        onClick={() => {
                          history.push(`/admin/members/${info?.row?.original?._id}/missions`);
                        }}
                      >
                        Lịch sử nhận xu
                      </MenuItem>
                    </AuthorizationComponentWrapper> */}
                    {/* <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'VIEW_HISTORY_ORDER_MEMBER'}>
                      <MenuItem
                        onClick={() => {
                          history.push(`/admin/member/${info?.row?.original?._id}/histories-coin`);
                        }}
                      >
                        Lịch sử bù trừ số dư
                      </MenuItem>
                    </AuthorizationComponentWrapper> */}
                    <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'VIEW_DETAIL_MEMBER'}>
                      <MenuItem onClick={() => handleViewClick(info?.row?.original?._id, ModalType.Preview)}>Chi tiết khách hàng</MenuItem>
                      <MenuItem onClick={() => handleResetPassword(info?.row?.original?.username)}>Đặt lại mật khẩu</MenuItem>
                    </AuthorizationComponentWrapper>
                  </MenuList>
                </>
              )}
            </Menu>
            
          </Flex>
        ),
      }),
    ],
    [categorysData]
  );

  const table = useReactTable({
    data: categorysData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box
                    isTruncated
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={6}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default SizeTable;
