import { useState } from 'react';
import { Button, Flex, FormControl, FormLabel, Input, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Pagination from 'components/Pagination/Pagination';
import { useQueryGetPhoneService } from 'services/phone';
import { useParams } from 'react-router-dom';
import TableService from './TableService';

export default function ListServicePhone() {
  const textColor = useColorModeValue('gray.700', 'white');
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 100,
  });
  const [searchTitle, setSearchTitle] = useState('');
  const [status, setStatus] = useState();
  const params = useParams();
  const { id } = params || {};

  const { data: phones } = useQueryGetPhoneService(
    {
      ...filter,
      phoneNumber: id,
    },
    {
      enable: !!id,
    }
  );

  const onFilter = () => {
    setFilter({
      ...filter,
      searchKeyword: searchTitle,
      countryCode: status?.value,
    });
  };

  const onReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 100,
    });
    setSearchTitle('');
    setStatus(null);
  };
  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
        <Card p="16px" mb="24px" bg="#fff">
          <CardHeader p="12px 5px" mb="12px">
            <Flex justifyContent={'space-between'}>
              <Flex direction={'column'}>
                <Flex direction="column" gap={'30px'}>
                  <Text fontSize="xl" color={textColor} fontWeight="bold">
                    Danh sách dịch vụ của số {phones?.data?.[0]?.phoneNumber || id}
                  </Text>
                </Flex>
                <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} my={'20px'}>
                  <Stack>
                    <Flex gap={'20px'} flexDirection={'column'} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                      <Flex alignItems={'center'} justifyContent={'start'} gap={5} flexWrap="wrap">
                        <FormControl w="200px">
                          <FormLabel>Tìm kiếm</FormLabel>
                          <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                        </FormControl>
                        <Button maxH="40px" alignSelf={'end'} onClick={onReset}>
                          <Text fontSize="md" fontWeight="bold" cursor="pointer">
                            Cài lại
                          </Text>
                        </Button>
                        <Button variant="primary" maxH="40px" px={5} alignSelf={'end'} onClick={onFilter}>
                          <Text fontSize="md" fontWeight="bold" cursor="pointer">
                            Tìm kiếm
                          </Text>
                        </Button>
                      </Flex>
                    </Flex>
                  </Stack>
                </Flex>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody overflowX="auto">
            <Stack overflow={'auto'}>
              <TableService categoryData={phones?.data || []} />
            </Stack>
            <Flex justifyContent={'flex-end'}>
              {!isEmpty(phones?.data) && (
                <Pagination
                  page={phones?.pagination?.page}
                  pageLength={phones?.pagination?.pageSize}
                  totalRecords={phones?.pagination?.count}
                  onPageChange={(page, pageLength) => {
                    setFilter({
                      ...filter,
                      pageSize: pageLength,
                      pageIndex: page - 1,
                    });
                  }}
                />
              )}
            </Flex>
          </CardBody>
        </Card>
      </Flex>
    </>
  );
}
