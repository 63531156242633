import { Box, Flex, IconButton, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { FaUserLock } from 'react-icons/fa';
import isEmpty from 'lodash/isEmpty';
import { FORMAT_DATE, ModalType } from 'constants/common';
import { toast } from 'components/Toast';
import { formatCurrencyToVND, formatDate, formatDateVN } from 'utils/helpers';
import { useDeleteUserMutation, useResetPasswordUserMutation } from 'services/user';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';

const SizeTable = ({ userInfo, users, refetch, handleUpdateCategory }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const deleteAdminMutation = useDeleteUserMutation();
  const resetPasswordAdminMutation = useResetPasswordUserMutation();

  const handleDeleteSize = async admin => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa tài khoản quản trị này không?');
    if (!confirmDelete) {
      return;
    }
    deleteAdminMutation.mutate(
      { userId: admin?._id },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Xóa tài khoản quản trị thành công');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Xóa tài khoản quản trị không thành công');
          refetch?.();
        },
      }
    );
  };

  // const handleResetPassword = async admin => {
  //   const confirmDelete = window.confirm('Bạn có chắc chắn muốn đặt lại mật khẩu cho tài khoản này không?');
  //   if (!confirmDelete) {
  //     return;
  //   }
  //   resetPasswordAdminMutation.mutate(
  //     { id: admin?._id },
  //     {
  //       onSuccess: () => {
  //         toast.showMessageSuccess('Đặt lại mật khẩu cho tài khoản này thành công');
  //       },
  //       onError: () => {
  //         toast.showMessageError('Đặt lại mật khẩu cho tài khoản này không thành công');
  //       },
  //     }
  //   );
  // };

  const columns = useMemo(
    () => [
      columnHelper.accessor('username', {
        header: 'Tên đăng nhập',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('role', {
        header: 'Vai trò',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày tạo',
        cell: info => <Text whiteSpace={'nowrap'}>{formatDateVN(info.row.original.createdAt, FORMAT_DATE)}</Text>,
      }),
      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => (
          <Flex alignItems="center" gap={1}>
            {info?.row?.original?.role === 'manager' && (
              <IconButton
                bg="transparent"
                onClick={() => {
                  handleUpdateCategory(info?.row?.original, ModalType.EmailBackup);
                }}
              >
                <FaUserLock color="red.400" boxSize={4} />
              </IconButton>
            )}
            {
              <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'RESET_PASSWORD_ADMIN'}>
                <IconButton
                  bg="transparent"
                  onClick={() => {
                    // handleResetPassword(info?.row?.original);
                    handleUpdateCategory(info?.row?.original, ModalType.ResetPassword);
                  }}
                >
                  <EditIcon cursor="pointer" boxSize={4} />
                </IconButton>
              </AuthorizationComponentWrapper>
            }
            <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'DELETE_ADMIN'}>
              <IconButton
                bg="transparent"
                onClick={() => {
                  handleDeleteSize(info?.row?.original);
                }}
              >
                <DeleteIcon color="red.400" boxSize={4} />
              </IconButton>
            </AuthorizationComponentWrapper>
          </Flex>
        ),
      }),
    ],
    [users]
  );

  const table = useReactTable({
    data: users || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box cursor={header.column.getCanSort() ? 'pointer' : 'default'} onClick={header.column.getToggleSortingHandler()}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={6}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default SizeTable;
