import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getBanks = params => request.get('/api/v1/banks', { params });
export const createBankFromVietQR = data => request.post('/api/v1/banks/create', data);

export const getBankAccount = params => request.get('/api/v1/bank-accounts', { params });
export const createBankAccount = data => request.post('/api/v1/bank-accounts/create', data);
export const deleteBankAccount = data => request.post('/api/v1/bank-accounts/delete', data);

export const createQRCode = data => request.post('/api/v1/transactions/create-via-qr', data);
export const createBankTransaction = data => request.post('/api/v1/transactions/create-payment', data);
export const createQRCodeMomo = data => request.post('/api/v1/transactions/create', data);
export const cancelTransaction = data => request.post('/api/v1/transactions/reject', data);

export const getDepositHistory = params => request.get('/api/v1/my-transactions', { params });
export const getDepositHistoryAdmin = params => request.get('/api/v1/transactions', { params });
export const changeStatusDeposit = data => request.post('/api/v1/transactions/change-status', data);

// Mutation
export const useCreateBankFromVietQRMutation = () => useMutation({ mutationFn: createBankFromVietQR });
export const useCreateBankAccountMutation = () => useMutation({ mutationFn: createBankAccount });
export const useDeleteBankAccountMutation = () => useMutation({ mutationFn: deleteBankAccount });
export const useCreateQRCodeMutation = () => useMutation({ mutationFn: createQRCode });
export const useCreateQRCodeMomoMutation = () => useMutation({ mutationFn: createQRCodeMomo });
export const useChangeStatusDepositMutation = () => useMutation({ mutationFn: changeStatusDeposit });
export const useCreateBankTransactionMutation = () => useMutation({ mutationFn: createBankTransaction });
export const useCancelTransactionMutation = () => useMutation({ mutationFn: cancelTransaction });

// Query
export const useQueryGetBanks = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_BANK', params], queryFn: () => getBanks(params), ...options });
export const useQueryGetBankAccount = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_BANK_ACCOUNT', params], queryFn: () => getBankAccount(params), ...options });
export const useQueryGetDepositHistory = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_DEPOSIT_HISTORY', params], queryFn: () => getDepositHistory(params), ...options });
export const useQueryGetDepositHistoryAdmin = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_DEPOSIT_HISTORY_ADMIN', params], queryFn: () => getDepositHistoryAdmin(params), ...options });
