import React from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { Input } from '@chakra-ui/react';
import { ModalType } from 'constants/common';

const ModalShowDetailMember = ({ isOpen, onClose, memberDetail }) => {
  const cancelRef = React.useRef();
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Preview);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'} maxH={'400pxs'}>
          <AlertDialogHeader textTransform="uppercase">Chi tiết khách hàng</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <label>Tên người dùng</label>
            <Input mb={2} value={memberDetail?.username + " - " + memberDetail?.fullname} readOnly />
            <label>Số điện thoại</label>
            <Input mb={2} value={memberDetail?.phone} readOnly />
            <label>Email</label>
            <Input mb={2} value={memberDetail?.email} readOnly />
            <label>Địa chỉ</label>
            <Input mb={2} value={memberDetail?.location || '--'} readOnly />
            <label>Trạng thái chặn tài khoản</label>
            <Input mb={2} value={memberDetail?.statusBlock || '--'} readOnly />
            <label>Trạng thái tài khoản</label>
            <Input mb={2} value={memberDetail?.status || '--'} readOnly />
            <label>Trạng thái thông báo</label>
            <Input mb={2} value={memberDetail?.isNotification ? 'Nhận thông báo' : 'Tắt thông báo'} readOnly />
            <label>Trạng thái bình luận</label>
            <Input mb={2} value={memberDetail?.isBlockComment ? 'Cho phép bình luận' : 'Không được phép bình luận'} readOnly />
            <label>Mã giới thiệu</label>
            <Input mb={2} value={memberDetail?.inviteCode || '--'} readOnly />
            <label>Số dư</label>
            <Input mb={2} value={memberDetail?.balance} readOnly />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Preview);
              }}
            >
              Đóng
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default ModalShowDetailMember;
