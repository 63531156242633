import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { ModalType } from 'constants/common';
import SizeTable from './components/Table';
import CreateCategoryModal from './components/CreateCategoryModal';
import Pagination from 'components/Pagination/Pagination';
import { toast } from 'components/Toast';
import { useExportHistoryServicesMutation, useQueryGetListFastService } from 'services/service';
import { downloadFile } from 'utils/helpers';
import { socket } from 'config/socket';
import { useUserState } from 'context/UserContext';

function HistoryRentPhone() {
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [sizeEditing, setSizeEditing] = useState(null);
  const [searchTitle, setSearchTitle] = useState('');
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 100,
  });
  const exportTemplateMutation = useExportHistoryServicesMutation(
    {},
    {
      responseType: 'arraybuffer',
    }
  );
  const { userInfo } = useUserState();

  useEffect(() => {
    if (!CookieStorage.isAuthenticated()) {
      return history.push('/auth/sign-in');
    }
  }, []);

  const { isOpen: isCreateModalOpen, onOpen: onOpenCreateModal, onClose: onCloseCreateModal } = useDisclosure();
  const { isOpen: isChangeStatusModalOpen, onOpen: onOpenChangeStatusModal, onClose: onCloseChangeStatusModal } = useDisclosure();
  const openModal = useMemo(
    () => ({
      [ModalType.Add]: onOpenCreateModal,
      [ModalType.ChangeStatus]: onOpenChangeStatusModal,
    }),
    [onOpenCreateModal, onOpenChangeStatusModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Add]: onCloseCreateModal,
      [ModalType.ChangeStatus]: onCloseChangeStatusModal,
    }),
    [onCloseCreateModal, onCloseChangeStatusModal]
  );

  const { data: histories, refetch } = useQueryGetListFastService(
    { ...filter, searchKeyword: filter.searchTitle },
    { enabled: CookieStorage.isAuthenticated() }
  );

  const handleUpdateItem = (size, modalType) => {
    openModal?.[modalType]?.();
    setSizeEditing(size);
  };

  const handelCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setSizeEditing(null);
  };

  const handleSearch = () => {
    setFilter({
      ...filter,
      searchTitle: searchTitle,
    });
  };

  const handleReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 100,
    });
    setSearchTitle('');
  };

  const onDownloadTemplate = () => {
    exportTemplateMutation.mutate(undefined, {
      onSuccess: response => {
        downloadFile(response, 'history-service');
        toast.showMessageSuccess('Tải dữ liệu lịch sử xuống thành công');
      },
      onError: () => {
        toast.showMessageError('Tải dữ liệu lịch sử xuống thất bại');
      },
    });
  };

  useEffect(() => {
    socket.on(`SEND_CODE_FOR_MEMBER_${userInfo?._id}`, res => {
      if (res) {
        refetch();
      }
    });
  }, []);
  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card px="16px" pb="16px" mb="24px" bg="transparent">
        <Card mb={5} bg="#fff" className="shadow-bg" borderRadius="16px">
          <CardHeader
            p={{ base: '12px', md: '16px' }}
            mb="12px"
            display="flex"
            flexDirection={{ base: 'column', md: 'row' }} // Thay đổi hướng flex cho mobile
            justifyContent={{ base: 'flex-start', md: 'space-between' }} // Căn giữa cho mobile và justify-between cho màn lớn
            alignItems="center"
            width="100%" // Đặt chiều rộng 100% cho CardHeader
          >
            <Text fontSize={{ base: 'xl', md: '22px' }} color={textColor} fontWeight="bold">
              Danh sách {histories?.pagination?.pageSize} số gần nhất đã thuê
            </Text>
            <Button
              onClick={() => history.push('/admin/fastrequestservices-option')}
              variant="primary"
              width={{ base: '100%', md: 'auto' }} // Chiều rộng 100% cho mobile
              mt={{ base: '10px', md: '0' }} // Khoảng cách trên cho mobile
            >
              <Text fontSize="md" fontWeight="bold" cursor="pointer">
                Thuê số
              </Text>
            </Button>
          </CardHeader>
          <CardBody
            borderRadius="15px"
            bg="white"
            border="1px solid gray.200"
            boxShadow="inset 0 0 1px 1px hsl(0deg 0% 100% / 90%), 0 20px 27px 0 rgb(0 0 0 / 5%)"
          >
            <Flex justifyContent={'space-between'} alignItems={'center'} gap={{ base: '10px', md: '20px' }}>
              <Stack width={{ base: '100%', md: 'auto' }}>
                <Flex gap={'10px'} alignItems={'end'} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                  <FormControl minWidth={{ base: 'full', sm: '200px' }}>
                    <FormLabel>Tìm kiếm dịch vụ</FormLabel>
                    <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                  </FormControl>
                  <Button maxH="60px" alignSelf={'end'} px={{ base: '5', md: '10'}} onClick={handleReset}>
                    <Text fontSize="md" fontWeight="bold" cursor="pointer">
                      Cài lại
                    </Text>
                  </Button>
                  <Button variant="primary" maxH="40px" px={{ base: '5', md: '10'}} alignSelf={'end'} onClick={handleSearch}>
                      <Text fontSize="md" fontWeight="bold" cursor="pointer">
                        Tìm kiếm
                      </Text>
                    </Button>
                  <Button maxH="40px" px={{ base: '5', md: '10'}} alignSelf={'end'} onClick={onDownloadTemplate} variant="primary">
                    <Text fontSize="md" fontWeight="bold" cursor="pointer">
                      Tải xuống
                    </Text>
                  </Button>
                </Flex>
              </Stack>
            </Flex>
          </CardBody>
        </Card>
        <CardBody overflowX="auto" pt={10} bg="#fff" className="shadow-bg" borderRadius="16px">
          <Stack overflow={'auto'}>
            <SizeTable categoryData={histories?.data || []} handleUpdateCategory={handleUpdateItem} refetch={refetch} />
          </Stack>
          <Flex justifyContent={'flex-end'}>
            <Pagination
              page={histories?.pagination?.page}
              pageLength={histories?.pagination?.pageSize}
              totalRecords={histories?.pagination?.count}
              onPageChange={(page, pageLength) => {
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
              }}
            />
          </Flex>
        </CardBody>
      </Card>
      {isCreateModalOpen && (
        <CreateCategoryModal categorysDetail={sizeEditing} isOpen={isCreateModalOpen} onClose={handelCloseModal} refetch={refetch} />
      )}
    </Flex>
  );
}

export default HistoryRentPhone;
